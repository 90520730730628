import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow,
  Typography
} from '@material-ui/core'
import Table from '../../components/Table'
import { toCapitalize } from '../../utils/utils'
import api from '../../libs/api'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import DeleteDialog from '../../components/DeleteDialog'
import { Add, Edit, Delete } from '@mui/icons-material'
import { AuthContext } from '../../libs/contexts/AuthContext'
import { SnackbarContext } from '../../libs/contexts/SnackbarContext'
import { handleApiError } from '../../libs/handleApiError'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import {
  formatDate,
  shiftPickerDateToTZDate,
  shiftTzDateToPickerDate
} from '../../utils/dateUtils'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const localizer = momentLocalizer(moment)

const MyBookingsPage = props => {
  let navigate = useNavigate()
  let { id } = useParams()
  const { auth, isLoading } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [bookings, setBookings] = useState()
  const [detailDialog, setDetailDialog] = useState({ open: false, data: {} })
  const [deleteDialog, setDeleteDialog] = useState({ open: false })

  const fetchData = async values => {
    try {
      const { data } = await api.get(`v1/booking/customer`)
      setBookings(data)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const handleSubmit = async values => {
    try {
      if (values.formType == 'Add') {
        const { data } = await api.post(`v1/technician`, values)
      } else {
        const { data } = await api.put(`v1/technician`, values)
      }
      fetchData()
      //   setAddDialog({ open: false })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const handleDelete = async id => {
    try {
      const { data } = await api.delete(`v1/bookings/${id}`)
      fetchData()
      setDeleteDialog({ open: false })
      openSnackbar({
        open: true,
        color: 'success',
        message: 'Successfully Deleted'
      })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (!bookings) {
    isLoading(true)
    return 'Loading...'
  }
  return (
    <>
      {isLoading(false)}
      <DeleteDialog
        dialog={deleteDialog}
        handleCancel={() => setDeleteDialog({ open: false })}
      />
      <Paper style={{ padding: '1em 2em 0 2em' }} elevation={5}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginBottom: '1em' }}
        >
          <Grid item style={{ display: 'flex' }}>
            {/* <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton> */}
            <Typography
              variant='h6'
              style={{ display: 'inline', marginTop: '.5em' }}
            >
              My Bookings
            </Typography>
          </Grid>
          <Grid item>
            {/* <Button
              variant='contained'
              color='primary'
              startIcon={<Add />}
              onClick={() => {
                //    navigate('/product/add')
                setAddDialog({ open: true, formType: 'Add' })
              }}
            >
              Add Technician
            </Button> */}
          </Grid>
        </Grid>
        <Calendar
          localizer={localizer}
          events={bookings.map(item => ({
            start: item.bookingdate
              ? shiftTzDateToPickerDate(new Date(item.bookingdate))
              : null,
            end: item.bookingdate
              ? shiftTzDateToPickerDate(new Date(item.bookingdate))
              : null,
            title: (
              <div onClick={() => setDetailDialog({ open: true, data: item })}>
                {item.customer?.name} ({item.technician?.name})
              </div>
            )
          }))}
          //   events={[
          //     {
          //       start: moment().toDate(),
          //       end: moment()
          //         .add(1, 'days')
          //         .toDate(),
          //       title: 'Some title'
          //     }
          //   ]}
          startAccessor='start'
          endAccessor='end'
          style={{ height: 700 }}
        />
        {/* <Table
          title={'Products'}
          columns={[
            { field: 'bookingdate', title: 'Booking Date' },
            {
              field: 'customer',
              title: 'Customer Name',
              render: rowData => rowData.customer?.name
            }
          ]}
          data={bookings}
          actions={[
            // {
            //   icon: () => <Add />,
            //   tooltip: 'Add Products',
            //   isFreeAction: true,
            //   onClick: () => {
            //     //   setAddDialog({ open: true })
            //     navigate('/product/add')
            //   }
            // },
            // {
            //   icon: () => <Edit />,
            //   tooltip: 'Edit',
            //   onClick: (event, rowData) => {
            //     setAddDialog({ open: true, formType: 'Edit', ...rowData })
            //     // navigate(`/product/edit/${rowData.id}`)
            //   }
            // },
            rowData => ({
              icon: () => <Delete />,
              tooltip: 'Delete',
              onClick: (event, rowData) => {
                setDeleteDialog({
                  open: true,
                  handleDelete: () => handleDelete(rowData.id),
                  message: `Are you sure you want to delete this Booking ${rowData.name}?`
                })
              }
              // disabled: rowData.birthYear < 2000
            })
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            // header: false,
            toolbar: false
          }}
          components={{
            Container: props => <div {...props}></div>
          }}
        /> */}
      </Paper>
      <Dialog
        open={detailDialog.open}
        onClose={() => {
          setDetailDialog({ open: false })
        }}
        fullWidth={true}
        maxWidth='md'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        TransitionComponent={Grow}
      >
        <DialogTitle id='alert-dialog-title'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>Booking Details</div>
            <div
              style={{
                color: 'white',
                backgroundColor: 'green',
                borderRadius: '1em',
                fontSize: '.8em',
                padding: '.3em 1em .3em 1em'
              }}
            >
              Technician Name : {detailDialog.data?.technician?.name}
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {[
              {
                label: 'Booking Date',
                value: formatDate(new Date(detailDialog.data?.bookingdate))
              },
              {
                label: 'Customer Name',
                value: detailDialog.data?.customer?.name
              },
              {
                label: 'Customer Phone',
                value: detailDialog.data?.customer?.mobile
              },
              {
                label: 'Customer Email',
                value: detailDialog.data?.customer?.authemail
              },
              {
                label: 'Customer Address',
                value:
                  detailDialog.data?.customer?.address1 +
                  ', ' +
                  detailDialog.data?.customer?.state +
                  ', ' +
                  detailDialog.data?.customer?.zipcode
              },
              //   {
              //     label: 'Technician Name',
              //     value: detailDialog.data?.technician?.name
              //   },
              { label: 'Timeslot', value: detailDialog.data?.timeslot },
              { label: 'Category 1', value: detailDialog.data?.category1 },
              { label: 'Category 2', value: detailDialog.data?.category2 },
              { label: 'Category 3', value: detailDialog.data?.category3 },
              { label: 'Product', value: detailDialog.data?.category4 },
              {
                label: 'Questions',
                value: detailDialog.data?.questions?.map(item => (
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        fontWeight: 'normal',
                        color: 'grey'
                      }}
                    >
                      {item.questiontext} -{' '}
                    </div>
                    <div style={{ marginLeft: '1em' }}> {item.answers}</div>
                  </div>
                ))
              },
              {
                label: 'More Details about Issue',
                value: detailDialog.data?.modedetails
              }
            ].map(item => (
              <>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: 'flex',
                    fontWeight: 'bold',
                    color: 'grey',
                    fontSize: 'large'
                  }}
                >
                  <div>{item.label}</div>
                  <div style={{ marginLeft: '1em' }}>:</div>
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{ fontWeight: 'bold', fontSize: 'large' }}
                >
                  {/* <div className='font-weight-bold text-black-100 font-size-lg d-flex align-items-center w-100'> */}
                  {/* {detailDialog.data[item.value]} */}
                  {item.value}
                  {/* </div> */}
                </Grid>
              </>
            ))}
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                fontWeight: 'bold',
                color: 'grey',
                fontSize: 'large'
              }}
            >
              <div>Images</div>
              <div style={{ marginLeft: '1em' }}>:</div>
            </Grid>

            <Grid item xs={8}>
              {detailDialog.data?.files?.map(item => {
                if (item.valueUrl) {
                  return (
                    <img
                      src={item.valueUrl}
                      style={{
                        width: '10em',
                        height: '10em',
                        marginRight: '2em'
                      }}
                      onClick={() => {
                        window.open(item.valueUrl)
                      }}
                    />
                  )
                } else {
                  return <></>
                }
              })}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  )
}

export default MyBookingsPage
