import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow, Chip,
  Typography, FormHelperText
} from '@material-ui/core'
import { toCapitalize } from '../../utils/utils'
import api from '../../libs/api'
import { Field, Form, Formik, useFormikContext } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import FormikDatePicker from '../../components/FormikDatePicker'
import DeleteDialog from '../../components/DeleteDialog'
import { Add, Edit, Delete } from '@mui/icons-material'
import { AuthContext } from '../../libs/contexts/AuthContext'
import { SnackbarContext } from '../../libs/contexts/SnackbarContext'
import { handleApiError } from '../../libs/handleApiError'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import dayjs from 'dayjs';
import {
  formatDate,
  shiftPickerDateToTZDate,
  shiftTzDateToPickerDate
} from '../../utils/dateUtils'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import FormikSelect from '../../components/FormikSelect'
import { Tooltip } from '@mui/material'

const localizer = momentLocalizer(moment)

const BookingsPage = props => {
  let navigate = useNavigate()
  let { id } = useParams()
  const { auth, isLoading } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [bookings, setBookings] = useState()
  const [offlineBookings, setOfflineBookings] = useState()
  const [detailDialog, setDetailDialog] = useState({ open: false, data: {} })
  const [addBookingDialog, setAddBookingDialog] = useState({ open: false, data: {} })
  const [deleteDialog, setDeleteDialog] = useState({ open: false })
  const [allTimeslots, setAllTimeslots] = useState([])
  const [technicians, setTechnicians] = useState([])
 
  const fetchData = async values => {
    try {
      const { data } = await api.get(`v1/booking`)
      setBookings(data)
      const mappedEvents = data.map((item) => ({
        id: item.id,
        // start: '2024-01-31T15:30:00',
        // end: '2024-01-31T16:30:00',
        start: item.timeslot && moment(item.bookingdate + '  ' + item.timeslot.split(' - ')[0], "YYYY-MM-DD hh:mmA").format("YYYY-MM-DDTHH:mm:ss"), // Replace ‘startDate’ with the field name from your API
        end: item.timeslot && moment(item.bookingdate + ' ' + item.timeslot.split(' - ')[1], "YYYY-MM-DD hh:mmA").format("YYYY-MM-DDTHH:mm:ss"), // Replace ‘endDate’ with the field name from your API   

        title: (
          <div onClick={() => setDetailDialog({ open: true, data: item })}>
            {/* Day view of cal  */}
            <Tooltip title={`${item.customer?.name} ${item.technician?.name ? `(${item.technician?.name ?? ''})` : ''}`}>
              {item.customer?.name} {item.technician?.name ? `(${item.technician?.name ?? ''})` : ''}
            </Tooltip>
          </div>
        ),
        // Replace ‘eventName’ with the field name from your API
        // You can add other fields here if needed, e.g., ‘allDay’, ‘resource’, etc.
      }));
      // setEvents((prev) => ({ ...prev, ...mappedEvents }));
      setEvents((prev) => [...prev, ...mappedEvents])



    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const [events, setEvents] = useState([]);
  const fetchOffineBookings = async values => {
    try {
      const { data } = await api.get(`v1/booking/admin/getOfflineBookings`)
      setOfflineBookings(data)
      const mappedEvents2 = data.map((item) => ({
        id: item.id,
        // start: '2024-01-31T15:30:00',
        // end: '2024-01-31T16:30:00',
        start: item.timeslot && moment(item.bookingdate + '  ' + item.timeslot.split(' - ')[0], "YYYY-MM-DD hh:mmA").format("YYYY-MM-DDTHH:mm:ss"), // Replace ‘startDate’ with the field name from your API
        end: item.timeslot && moment(item.bookingdate + ' ' + item.timeslot.split(' - ')[1], "YYYY-MM-DD hh:mmA").format("YYYY-MM-DDTHH:mm:ss"), // Replace ‘endDate’ with the field name from your API   

        title: (
          <div onClick={() => setDetailDialog({ open: true, data: item })}>
            {/* Day view of cal  */}
            <Tooltip title={`${item.customer?.name} ${item.technician?.name ? `(${item.technician?.name ?? ''})` : ''}`}>
              {item.customer?.name} {item.technician?.name ? `(${item.technician?.name ?? ''})` : ''}
            </Tooltip>
          </div>
        ),
        // Replace ‘eventName’ with the field name from your API
        // You can add other fields here if needed, e.g., ‘allDay’, ‘resource’, etc.
      }));
      setEvents((prev) => [...prev, ...mappedEvents2])



    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const fetchTimeslot = async (bDate) => {
    try {
      let weekdays = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ]
      let date = moment(bDate)
      let day = date.day()
      const { data } = await api.get(`v1/time/day/${weekdays[day]}`)
      setAllTimeslots(data)
      // setTimeslots(data.filter(item => item.calendarType == appointment.vHour))
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const fetchTechnicians = async (values) => {
    try {
      const { data } = await api.get(`v1/technician`);
      setTechnicians(data);
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };
  const handleAddBookingSubmit = async values => {
    try {
      // if (values.formType == 'Add') {
      //   const { data } = await api.post(`v1/technician`, values)
      // } else {}
      const { data } = await api.post(`v1/booking/admin`,
        {
          ...values,
          bookingdate: moment(values.bDate.$d).format('YYYY-MM-DD'),
          customer: {
            name: values.name,
            address1: values.address1,
            zipcode: values.zipcode
          }
        })
      openSnackbar({
        open: true,
        color: 'success',
        message: 'Successfully Added'
      })
      fetchData()
      fetchOffineBookings()
      setAddBookingDialog({ open: false })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const handleDelete = async id => {
    try {
      const { data } = await api.delete(`v1/bookings/${id}`)
      fetchData()
      setDeleteDialog({ open: false })
      openSnackbar({
        open: true,
        color: 'success',
        message: 'Successfully Deleted'
      })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  useEffect(() => {
    fetchData()
    fetchOffineBookings()
    fetchTechnicians()
  }, [])

  if (!bookings || !offlineBookings) {
    isLoading(true)
    return 'Loading...'
  }


  return (
    <>
      {isLoading(false)}
      <Button onClick={() => navigate('/bookings/offline')} variant='outlined' style={{ backgroundColor: "black", color: "white", marginRight: 10, marginBottom: 3 }} size='small'  >Offline Bookings </Button>
      <Button onClick={() => navigate('/bookings/online')} variant='outlined' size='small' style={{ backgroundColor: "black", color: "white", marginBottom: 3 }} >Online Bookings </Button>

      <DeleteDialog
        dialog={deleteDialog}
        handleCancel={() => setDeleteDialog({ open: false })}
      />
      <Paper style={{ padding: '1em 2em 0 2em' }} elevation={5}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginBottom: '1em' }}
        >
          <Grid item style={{ display: 'flex' }}>
            {/* <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton> */}
            <Typography
              variant='h6'
              style={{ display: 'inline', marginTop: '.5em' }}
            >
              My Bookings
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              startIcon={<Add />}
              onClick={() => {
                //    navigate('/product/add')
                setAddBookingDialog({ open: true, formType: 'Add' })
              }}
            >
              Add Bookings
            </Button>
          </Grid>
        </Grid>
        <Calendar
          localizer={localizer}

          events={events}
          tooltipAccessor={null}


          style={{ height: 700 }
          }
          startAccessor={(events) => {
            return new Date(events.start);
          }}
          endAccessor={(events) => {
            return new Date(events.end);
          }}


        //   events={[
        //     {
        //       start: moment().toDate(),
        //       end: moment()
        //         .add(1, 'days')
        //         .toDate(),
        //       title: 'Some title'
        //     }
        //   ]}

        />

      </Paper >
      <Dialog
        open={detailDialog.open}
        onClose={() => {
          setDetailDialog({ ...detailDialog, open: false })
        }}
        fullWidth={true}
        maxWidth={detailDialog.data?.bookingType != 'Offline' ? detailDialog.data?.questions?.length > 4 ? 'lg' : 'md' : 'sm'}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        TransitionComponent={Grow}
      >
        <DialogTitle id='alert-dialog-title'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>Booking Details</div>
            <div
              style={{
                color: 'white',
                backgroundColor: 'green',
                borderRadius: '1em',
                fontSize: '.8em',
                padding: '.3em 1em .3em 1em'
              }}
            >
              Technician Name : {detailDialog.data?.technician?.name}
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {detailDialog.data?.bookingType != 'Offline' ?
            <Grid container spacing={1}>
              {[
                {
                  label: 'Booking Date',
                  value: formatDate(new Date(detailDialog.data?.bookingdate))
                },
                {
                  label: 'Customer Name',
                  value: detailDialog.data?.customer?.name
                },
                {
                  label: 'Customer Phone',
                  value: detailDialog.data?.customer?.mobile.replace(/\D+/g, '').replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3')
                },
                {
                  label: 'Customer Email',
                  value: detailDialog.data?.customer?.authemail
                },
                {
                  label: 'Customer Address',
                  value:
                    detailDialog.data?.customer?.address1 +
                    ', ' +
                    detailDialog.data?.customer?.state +
                    ', ' +
                    detailDialog.data?.customer?.zipcode
                },
                //   {
                //     label: 'Technician Name',
                //     value: detailDialog.data?.technician?.name
                //   },
                { label: 'Timeslot', value: detailDialog.data?.timeslot },
                { label: 'Category 1', value: detailDialog.data?.category1 },
                { label: 'Category 2', value: detailDialog.data?.category2 },
                { label: 'Category 3', value: detailDialog.data?.category3 },
                { label: 'Product', value: detailDialog.data?.category4 },
                {
                  label: 'Questions',
                  value: detailDialog.data?.questions?.map(item => (
                    <div style={{ display: 'flex', marginTop: '.3em' }}>
                      <div
                        style={{
                          fontWeight: 'normal',
                          color: 'grey'
                        }}
                      >
                        {item.questiontext}
                      </div>
                      <div style={{ marginLeft: '1em' }}> {item.answers}</div>
                    </div>
                  ))
                },
                {
                  label: 'More Details about Issue',
                  value: detailDialog.data?.modedetails
                }
              ].map(item => (
                <>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: 'flex',
                      fontWeight: 'bold',
                      color: 'grey',
                      fontSize: 'large'
                    }}
                  >
                    <div>{item.label}</div>
                    <div style={{ marginLeft: '1em' }}>:</div>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    style={{ fontWeight: 'bold', fontSize: 'large' }}
                  >
                    {/* <div className='font-weight-bold text-black-100 font-size-lg d-flex align-items-center w-100'> */}
                    {/* {detailDialog.data[item.value]} */}
                    {item.value}
                    {/* </div> */}
                  </Grid>
                </>
              ))}
              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  fontWeight: 'bold',
                  color: 'grey',
                  fontSize: 'large'
                }}
              >
                <div>Images</div>
                <div style={{ marginLeft: '1em' }}>:</div>
              </Grid>

              <Grid item xs={8}>
                {detailDialog.data?.files?.map(item => {
                  if (item.valueUrl) {
                    return (
                      <img
                        src={item.valueUrl}
                        style={{
                          width: '10em',
                          height: '10em',
                          marginRight: '2em'
                        }}
                        onClick={() => {
                          window.open(item.valueUrl)
                        }}
                      />
                    )
                  } else {
                    return <></>
                  }
                })}
              </Grid>
            </Grid> : <Grid container spacing={1}>
              {[
                {
                  label: 'Booking Date',
                  value: formatDate(new Date(detailDialog.data?.bookingdate))
                },
                {
                  label: 'Customer Name',
                  value: detailDialog.data?.customer?.name
                },
                {
                  label: 'Address',
                  value: detailDialog.data?.customer?.address1
                },
                {
                  label: 'Zipcode',
                  value: detailDialog.data?.customer?.zipcode
                },
                {
                  label: 'Timeslot', value: detailDialog.data?.timeslot
                },
              ].map(item => (
                <>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: 'flex',
                      fontWeight: 'bold',
                      color: 'grey',
                      fontSize: 'large'
                    }}
                  >
                    <div>{item.label}</div>
                    <div style={{ marginLeft: '1em' }}>:</div>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{ fontWeight: 'bold', fontSize: 'large' }}
                  >
                    {/* <div className='font-weight-bold text-black-100 font-size-lg d-flex align-items-center w-100'> */}
                    {/* {detailDialog.data[item.value]} */}
                    {item.value}
                    {/* </div> */}
                  </Grid>
                </>
              ))}
            </Grid>
          }
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addBookingDialog.open}
        onClose={() => {
          setAddBookingDialog({ open: false });
        }}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Grow}
      >
        <DialogTitle id="alert-dialog-title">
          Add Booking
        </DialogTitle>
        <Formik
          validationSchema={yup.object().shape({
            bDate: yup
              .string()
              .required("Booking Date is required."),
            name: yup
              .string()
              // .min(10,"Mobile is invalid")
              .required("Name is required."),
            // mobile: yup
            //   .number()
            //   .nullable()
            //   .min(10, "Mobile No. is invalid")
            //   .typeError("Mobile No. is invalid")
            //   .required("Required"),
            address1: yup
              .string()
              .required("Address is required."),
            zipcode: yup
              .string()
              .required("Zip Code is required."),
            technicianId: yup
              .string()
              .required("Technician is required."),
            appointment: yup
              .string()
              .required("Select Appointment window"),
            timeslotId: yup
              .string()
              .required("Timeslot is required."),
          })}
          initialValues={{
            // id: addDialog.id,
            bDate: "",
            name: "",
            mobile: "",
            address1: '',
            address2: '',
            state: "",
            zipcode: "",
            timeslotId: '',
            timeslot: '',
            timeslotOptions: [],
            technicianId: "",
            technician: '',
            appointment: '',
          }}
          onSubmit={handleAddBookingSubmit}
        >
          {({ values, getFieldProps, setFieldValue, errors }) => {
            return (
              <Form>
                <DialogContent style={{ margin: "0 1em 1em 1em" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormikDatePicker
                        name='bDate'
                        label='Booking Date'
                        onChange={e => {
                          setFieldValue('bDate', e)
                          fetchTimeslot(e)
                        }}
                        error={errors['bDate'] ?? false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="name"
                        label="Name"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="address1"
                        label="Address1"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="address2"
                        label="Address2"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="state"
                        label="State"
                        variant="standard"
                        fullWidth
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="zipcode"
                        label="Zipcode"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={FormikSelect}
                        label='Technician'
                        name='technicianId'
                        value={values.technicianId}
                        //   multiple
                        onChange={e => {
                          setFieldValue('technicianId', e.target.value)
                          setFieldValue('technician', technicians.find(t => t.id == e.target.value))
                        }}
                        size='small'
                        options={technicians.filter((item) => getFieldProps("zipcode").value && item.postcode.split(',').some(code =>code.trim() ===  getFieldProps("zipcode").value))
                          .map(item => ({ label: item.name, value: item.id }))}
                        textField='label'
                        valueField='value'
                      // inputProps={{ readOnly: view }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={FormikSelect}
                        label='Appointment Window'
                        name='appointment'
                        value={values.appointment}
                        //   multiple
                        onChange={e => {
                          setFieldValue('appointment', e.target.value)
                          setFieldValue('timeslotOptions', allTimeslots.filter(item => item.calendarType == e.target.value))
                          setFieldValue('timeslotId', '')
                          setFieldValue('timeslot', '')
                        }}
                        size='small'
                        options={values.bDate ? [1, 2, 3, 4].map(item => ({ label: `${item} Hour`, value: `${item}` })) : []}
                        textField='label'
                        valueField='value'
                      // inputProps={{ readOnly: view }}
                      />
                    </Grid>
                    {values.timeslotOptions.length != 0 &&
                      <Grid item xs={12}>
                        <div style={{ fontWeight: 'bold' }}>Timeslots</div>
                        <Grid
                          container
                          direction="row"
                          spacing={1}
                        >
                          {values.timeslotOptions.map(item =>
                            <Grid item key={item.id} >
                              <Chip label={item.description} color="primary"
                                variant={values.timeslotId == item.id ? 'default' : "outlined"}
                                onClick={() => {
                                  setFieldValue('timeslotId', item.id)
                                  setFieldValue('timeslot', item.description)
                                }} />
                            </Grid>)}
                        </Grid>
                        {errors['timeslotId'] &&
                          <FormHelperText error> {errors['timeslotId']}</FormHelperText>}
                      </Grid>
                    }
                  </Grid>
                </DialogContent>

                <DialogActions style={{ margin: "0 2em 1em 0" }}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Confirm
                  </Button>
                  <Button
                    size="large"
                    onClick={() => {
                      setAddBookingDialog({ open: false });
                    }}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  )
}

export default BookingsPage
