import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow,
  Typography,
  IconButton
} from '@material-ui/core'
import Table from '../../components/Table'
import { toCapitalize } from '../../utils/utils'
import api, { toFormData } from '../../libs/api'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import {
  Add,
  Edit,
  Delete,
  ArrowBack,
  AccountCircle
} from '@mui/icons-material'
import CustomerForm from '../../components/form/CustomerForm'
import { AuthContext } from '../../libs/contexts/AuthContext'
import { SnackbarContext } from '../../libs/contexts/SnackbarContext'
import { handleApiError } from '../../libs/handleApiError'
import DeleteDialog from '../../components/DeleteDialog'

const ChangePasswordPage = props => {
  let navigate = useNavigate()
  let { id } = useParams()
  const { auth, isLoading } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [addDialog, setAddDialog] = useState({ open: false })

  // const fetchInfo = async (values) => {
  //   try {
  //     const { data } = await api.get(`v1/categories`)
  //     setCategories(data)
  //     // openSnackbar({ open: true, color: 'success', message: 'Successful' })
  //   } catch (error) {
  //     handleApiError(error, openSnackbar)
  //   }
  // }

  // const handleSubmit = async (values) => {
  //   try {
  //     const { data } = await api.post(`v1/customers`, toFormData(values))
  //     // fetchData()
  //     // setAddDialog({ open: false })
  //     navigate(-1)
  //   } catch (error) {
  //     handleApiError(error, openSnackbar)
  //   }
  // }
  const handleSubmit = async values => {
    try {
      const { data } = await api.put(`v1/customers`, toFormData(values))
      // fetchData()
      // setAddDialog({ open: false })
      navigate(-1)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  useEffect(() => {}, [])

  // if (!categories) {
  //   isLoading(true)
  //   return 'Loading...'
  // }
  return (
    <>
      {/* {isLoading(false)} */}
      <Paper style={{ padding: '2em' }} elevation={5}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginBottom: '1em' }}
        >
          <Grid item style={{ display: 'flex' }}>
            {/* <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton> */}
            <Typography
              variant='h6'
              style={{
                display: 'inline',
                marginTop: '.4em'
              }}
            >
              Change Password
            </Typography>{' '}
          </Grid>
          <Grid item>
            {/* <Button
              variant='contained'
              color='secondary'
              startIcon={<Add />}
              onClick={() => {}}
            >
              Add Questions
            </Button> */}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Formik
              validationSchema={yup.object().shape({
                // name: yup
                //   .string()
                //   // .min(10,"Mobile is invalid")
                //   .required('Name is required.'),
                // mobile: yup
                //   .number()
                //   .nullable()
                //   .min(10, 'Mobile No. is invalid')
                //   .typeError('Mobile No. is invalid')
                //   .required('Required'),
                // email: yup
                //   .string()
                //   .email('Invalid email address')
                //   .required('Required')
              })}
              initialValues={{
                // id: addDialog.id,
                name: '',
                mobile: '',
                email: '',
                ...addDialog
              }}
              //   onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name='name'
                          // value={values.mobile}
                          // component={PhoneNumberInput}
                          // setFieldValue={(value) => {
                          //   setFieldValue("mobile", value);
                          // }}
                          type="password"
                          label='Old Password'
                          variant='standard'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name='mobile'
                          // value={values.mobile}
                          // component={PhoneNumberInput}
                          // setFieldValue={(value) => {
                          //   setFieldValue("mobile", value);
                          // }}
                          type="password"
                          label='New Password'
                          variant='standard'
                          fullWidth
                          inputProps={{ maxlength: '10' }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name='email'
                          // value={values.mobile}
                          // component={PhoneNumberInput}
                          // setFieldValue={(value) => {
                          //   setFieldValue("mobile", value);
                          // }}
                          type="password"
                          label='Confirm Password'
                          variant='standard'
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginTop: '2em' }}>
                        <Button
                          type='submit'
                          color='primary'
                          variant='contained'
                          // onClick={() => {
                          //   //   handleSendRequest();
                          // }}
                        >
                          Confirm
                        </Button>
                        <Button
                          size='large'
                          onClick={() => {
                            setAddDialog({ open: false, reviews: [] })
                          }}
                          color='primary'
                        >
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default ChangePasswordPage
