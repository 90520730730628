import * as React from "react";
import { Routes, Route, Link, Navigate, useNavigate } from "react-router-dom";
import Login from "../pages/auth/Login";
import Dashboard from "../pages/Dashboard";
import Topbar from "../layouts/Topbar";
import Sidebar from "../layouts/Sidebar1";
import Sidebar2 from "../layouts/Sidebar2";
import AuthLayout from "../layouts/AuthLayout.";
// import '../assets/styles/Layout1.css'
import { AuthContext } from "./contexts/AuthContext";
import Trello from "../pages/Trello";
// import ApiData from '../pages/ApiData'
import PlumbingPage from "../pages/categories/Plumbing";
import ProductsPage from "../pages/products/Products";
import AddProductsPage from "../pages/products/AddProduct";
import LabourMarkupPage from "../pages/markup/LabourMarkup";
import MaterialMarkupPage from "../pages/markup/MaterialMarkup";
import HvacPage from "../pages/categories/Hvac";
import QuestionsPage from "../pages/categories/Questions";
import EditProductsPage from "../pages/products/EditProducts";
import TimeslotPage from "../pages/timeslot/Timeslot";
import Customers from "../pages/customers/Customers";
import AddCustomerPage from "../pages/customers/AddCustomerPage";
import EditCustomerPage from "../pages/customers/EditCustomerPage";
import TechnicianPage from "../pages/technicians/Technicians";
import Bookings from "../pages/bookings/Bookings";
import CustomerLogin from "../pages/customer-pages/Login";
import MyProfilePage from "../pages/customer-pages/MyProfile";
import ChangePasswordPage from "../pages/customer-pages/ChangePassword";
import MyBookingsPage from "../pages/customer-pages/MyBooking";
import OfflineBooking from '../pages/bookings/OfflineBooking'
import OnlineBooking from '../pages/bookings/OnlineBooking'
import RearrangeProductPage from "../pages/products/Rearrange";

export default function AppRoutes() {
  const { auth, isAuth } = React.useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <>
      {/* <Topbar /> */}
      <Routes>
        <Route
          path="/"
          element={
            isAuth() ? <Navigate to="/bookings" /> : <Navigate to="/login" />
          }
          // element={<Navigate to='/bookings' />}
        />
        <Route element={<AuthLayout />}>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/customer-login" element={<CustomerLogin />} />
        </Route>
        {isAuth() == 1 ? (
          <Route element={<Sidebar />}>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/bookings' element={<Bookings />} />
            <Route exact path='/bookings/offline' element={<OfflineBooking />} />
            <Route exact path='/bookings/online' element={<OnlineBooking />} />
            <Route path='/customers' element={<Customers />} />
            <Route exact path='/customers/add' element={<AddCustomerPage />} />
            <Route
              exact
              path="/customers/edit/:id"
              element={<EditCustomerPage />}
            />
            <Route exact path="/product" element={<ProductsPage />} />
            <Route exact path="/product/add" element={<AddProductsPage />} />
            <Route
              exact
              path="/product/edit/:id"
              element={<EditProductsPage />}
            />
            <Route
              exact
              path="/product/rearrange"
              element={<RearrangeProductPage />}
            />
            <Route
              exact
              path="/material-markup"
              element={<MaterialMarkupPage />}
            />
            <Route exact path="/labour-markup" element={<LabourMarkupPage />} />
            <Route exact path="/technician" element={<TechnicianPage />} />
            <Route path="/timeslot/:id" element={<TimeslotPage />} />
            {/* <Route path='/booking' element={<BookingPage />} /> */}
            <Route path="/plumbing/:id" element={<PlumbingPage />} />
            <Route path="/hvac/:id" element={<HvacPage />} />
            <Route path="/questions/:id" element={<QuestionsPage />} />
            <Route path="/hvac/:id" element={<PlumbingPage />} />
            <Route path="/trelloboard" element={<Trello />} />
            {/* <Route path='/apidata' element={<ApiData />} /> */}
          </Route>
        ) : isAuth() == 2 ? (
          <Route element={<Sidebar2 />}>
            <Route path="/my-bookings" element={<MyBookingsPage />} />
            <Route path="/my-profile" element={<MyProfilePage />} />
            <Route path="/change-password" element={<ChangePasswordPage />} />
          </Route>
        ) : (
          <Route path="*" element={<Navigate to="/login" />}></Route>
        )}
        <Route
          path="*"
          element={
            <>
              <h1>Oops!</h1>
              <h3>This page does not exist</h3>
            </>
          }
        />
      </Routes>
    </>
  );
}
