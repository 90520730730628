import React, { useEffect, useState } from 'react'
import Table from '../../components/Table'
import api from '../../libs/api'
import { Box, CardHeader, Chip, Grid, IconButton, Paper, Tooltip, Typography } from '@material-ui/core'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import dayjs from 'dayjs';

function OnlineBooking() {
    const navigate = useNavigate()
    const [OnlineBookings, setOnlineBookings] = useState()

    const fetchOffineBookings = async values => {
        try {
            const { data } = await api.get(`v1/booking`)
            setOnlineBookings(data)
            console.log("customer", data?.map(item => item.customer))
        } catch (error) {
            // handleApiError(error, openSnackbar)
        }
    }
    useEffect(() => {
        fetchOffineBookings()
    }, [])

    return (
        <>
            <Paper style={{ padding: '1em 2em 0 2em' }} elevation={5}>

                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    style={{ marginBottom: '1em' }}
                >
                    <Grid item style={{ display: 'flex' }}>
                        <IconButton onClick={() => navigate('/bookings')}>
                            <ArrowBack sx={{ cursor: 'pointer' }} />
                        </IconButton>
                        <Typography
                            variant='h6'
                            style={{
                                display: 'inline',
                                marginTop: '.4em'
                            }}
                        >
                            Online Booking List
                        </Typography>{' '}
                    </Grid>
                </Grid>
                <Table
                    title={'OnlineBookings'}
                    columns={[
                        { field: 'customer.name', title: 'Name' },
                        {
                            field: 'bookingdate', title: 'Booking Date',
                            render: (rowData) => {
                                return dayjs(rowData.bookingdate).format('MM-DD-YYYY')
                            }
                        },
                        {
                            field: 'timeslot', title: 'Timeslot',
                            render: (rowData) => {
                                if (rowData.technician) {
                                    return <Chip variant='outlined' style={{}} label={rowData.timeslot} />
                                }
                                else {
                                    return null
                                }

                            }
                        },
                        {
                            field: 'bookingStatus', title: 'Booking Status',
                            render: (rowData) => {
                                return <Chip style={{ backgroundColor: rowData.bookingStatus === 'Active' ? 'rgb(135 221 140 / 87%)' : 'rgb(211 83 83 / 87%)' }} label={rowData.bookingStatus} />
                            }
                        },
                        {
                            field: 'technician', title: 'Technician',
                            render: (rowData) => {
                                if (rowData.technician) {
                                    return <CardHeader title={<Typography style={{ fontSize: "15px" }}>{rowData.technician.name}</Typography>} subheader={<Typography style={{ fontSize: "13px", color: 'gray' }} >{rowData.technician.category}</Typography>} />
                                }
                                else {
                                    return null
                                }
                            }
                        },
                        {
                            field: 'customer.mobile', title: 'Mobile',
                            render: (rowData) => {
                                const phoneNumber = rowData.customer.mobile
                                return <Box sx={{ width: "110px" }} >
                                    {phoneNumber ? (
                                        <>({phoneNumber.substring(0, 3)}) {phoneNumber.substring(3, 6)}-{phoneNumber.substring(6)}</>
                                    ) : null}
                                </Box>
                            }
                        },
                        { field: 'customer.authemail', title: 'Email' },
                        {
                            field: 'customer.address1', title: 'Address',
                            render: (rowData) => {
                                return <Tooltip title={rowData.customer.address1}>
                                    <Typography fontSize="large" noWrap style={{ textOverflow: "ellipsis", width: '130px', cursor: "pointer" }}>
                                        {rowData.customer.address1}
                                    </Typography>
                                </Tooltip>
                            }

                        },
                        // { field: 'city', title: 'City' },
                        { field: 'customer.state', title: 'State' },
                        { field: 'customer.zipcode', title: 'Zipcode' }
                        //     render: (rowData) => (
                        //       <img
                        //         src={rowData.url}
                        //         style={{ width: 50, borderRadius: "50%" }}
                        //       />
                        //     ),
                    ]}
                    data={OnlineBookings}
                    actions={[
                        // {
                        //   icon: () => <Add />,
                        //   tooltip: 'Add Customers',
                        //   isFreeAction: true,
                        //   onClick: () => {
                        //     //   setAddDialog({ open: true })
                        //     navigate('/customers/add')
                        //   }
                        // },
                        // {
                        //   icon: () => <Edit />,
                        //   tooltip: 'Edit',
                        //   onClick: (event, rowData) => {
                        //     navigate(`/customers/edit/${rowData.id}`)
                        //   }
                        // },
                        // rowData => ({
                        //   icon: () => <Delete />,
                        //   tooltip: 'Delete',
                        //   onClick: (event, rowData) => {
                        //     setDeleteDialog({
                        //       open: true,
                        //       handleDelete: () => handleDelete(rowData.id),
                        //       message: `Are you sure you want to delete?`
                        //     })
                        //   }
                        //   // disabled: rowData.birthYear < 2000
                        // })
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        // header: false,
                        toolbar: false
                    }}
                    components={{
                        Container: props => <div {...props}></div>
                    }}
                />
            </Paper>
        </>
    )
}

export default OnlineBooking