import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow,
  Typography,
  Box,
} from "@material-ui/core";
import Table from "../../components/Table";
import { toCapitalize } from "../../utils/utils";
import api from "../../libs/api";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import DeleteDialog from "../../components/DeleteDialog";
import { Add, Edit, Delete } from "@mui/icons-material";
import { AuthContext } from "../../libs/contexts/AuthContext";
import { SnackbarContext } from "../../libs/contexts/SnackbarContext";
import { handleApiError } from "../../libs/handleApiError";
import FormikSelect from "../../components/FormikSelect";

const ProductsPage = (props) => {
  let navigate = useNavigate();
  let { id } = useParams();
  const { auth, isLoading } = useContext(AuthContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const [products, setProducts] = useState();
  const [addDialog, setAddDialog] = useState({ open: false });
  const [deleteDialog, setDeleteDialog] = useState({ open: false });

  const fetchData = async (values) => {
    try {
      const { data } = await api.get(`v1/technician`);
      setProducts(data);
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };
  const handleSubmit = async (values) => {
    try {
      if (values.formType == "Add") {
        const { data } = await api.post(`v1/technician`, values);
      } else {
        const { data } = await api.put(`v1/technician`, values);
      }
      fetchData();
      setAddDialog({ open: false });
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };
  const handleDelete = async (id) => {
    try {
      const { data } = await api.delete(`v1/technician/${id}`);
      fetchData();
      setDeleteDialog({ open: false });
      openSnackbar({
        open: true,
        color: "success",
        message: "Successfully Deleted",
      });
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!products) {
    isLoading(true);
    return "Loading...";
  }
  return (
    <>
      {isLoading(false)}
      <DeleteDialog
        dialog={deleteDialog}
        handleCancel={() => setDeleteDialog({ open: false })}
      />
      <Paper style={{ padding: "1em 2em 0 2em" }} elevation={5}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: "1em" }}
        >
          <Grid item style={{ display: "flex" }}>
            {/* <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton> */}
            <Typography
              variant="h6"
              style={{ display: "inline", marginTop: ".5em" }}
            >
              Technicians
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => {
                //    navigate('/product/add')
                setAddDialog({ open: true, formType: "Add" });
              }}
            >
              Add Technician
            </Button>
          </Grid>
        </Grid>
        <Table
          title={"Products"}
          columns={[
            { field: "name", title: "Name" },
            {
              field: "mobile", title: "Mobile",
              render: (rowData) => {
                { console.log(rowData.mobile) }
                const phoneNumber = rowData.mobile
                return <Box sx={{ width: "130px" }} >
                  {phoneNumber ? (
                    <>({phoneNumber.substring(0, 3)}) {phoneNumber.substring(3, 6)}-{phoneNumber.substring(6)}</>
                  ) : null}
                </Box>
              }
            },
            { field: "email", title: "Email" },
            { field: "postcode", title: "Zip Code" },
            { field: "category", title: "Category" },
            { field: "numberOfJobs", title: "No. Of Jobs" },
            //     render: (rowData) => (
            //       <img
            //         src={rowData.url}
            //         style={{ width: 50, borderRadius: "50%" }}
            //       />
            //     ),
          ]}
          data={products}
          actions={[
            {
              icon: () => <Add />,
              tooltip: "Add Products",
              isFreeAction: true,
              onClick: () => {
                //   setAddDialog({ open: true })
                navigate("/product/add");
              },
            },
            {
              icon: () => <Edit />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                setAddDialog({ open: true, formType: "Edit", ...rowData });
                // navigate(`/product/edit/${rowData.id}`)
              },
            },
            (rowData) => ({
              icon: () => <Delete />,
              tooltip: "Delete",
              onClick: (event, rowData) => {
                setDeleteDialog({
                  open: true,
                  handleDelete: () => handleDelete(rowData.id),
                  message: `Are you sure you want to delete technician ${rowData.name}?`,
                });
              },
              // disabled: rowData.birthYear < 2000
            }),
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            // header: false,
            toolbar: false,
          }}
          components={{
            Container: (props) => <div {...props}></div>,
          }}
        />
      </Paper>
      <Dialog
        open={addDialog.open}
        onClose={() => {
          setAddDialog({ open: false });
        }}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Grow}
      >
        <DialogTitle id="alert-dialog-title">
          {addDialog.formType} Technician
        </DialogTitle>
        <Formik
          validationSchema={yup.object().shape({
            name: yup
              .string()
              // .min(10,"Mobile is invalid")
              .required("Name is required."),
            postcode: yup
              .string()
              // .min(10,"Mobile is invalid")
              .required("Zip Code is required."),
            mobile: yup
              .number()
              .nullable()
              .min(10, "Mobile No. is invalid")
              .typeError("Mobile No. is invalid")
              .required("Required"),
            email: yup
              .string()
              .email("Invalid email address")
              .required("Required"),
            numberOfJobs: yup
              .string()
              .required("Number of Jobs is required."),
            category: yup
              .string()
              .required("Category is required."),
          })}
          initialValues={{
            // id: addDialog.id,
            name: "",
            mobile: "",
            email: "",
            postcode: "",
            numberOfJobs: '',
            category: '',
            ...addDialog,
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <DialogContent style={{ margin: "0 1em 1em 1em" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="name"
                        // value={values.mobile}
                        // component={PhoneNumberInput}
                        // setFieldValue={(value) => {
                        //   setFieldValue("mobile", value);
                        // }}
                        label="Name"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="mobile"
                        // value={values.mobile}
                        // component={PhoneNumberInput}
                        // setFieldValue={(value) => {
                        //   setFieldValue("mobile", value);
                        // }}
                        label="Mobile"
                        variant="standard"
                        fullWidth
                        inputProps={{ maxlength: "10" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="email"
                        // value={values.mobile}
                        // component={PhoneNumberInput}
                        // setFieldValue={(value) => {
                        //   setFieldValue("mobile", value);
                        // }}
                        label="Email"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="postcode"
                        label="Zip Code"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="numberOfJobs"
                        label="Number of Jobs"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={FormikSelect}
                        label='Category'
                        name='category'
                        value={values.category}
                        //   multiple
                        onChange={e => {
                          setFieldValue('category', e.target.value)
                        }}
                        size='small'
                        options={[{ label: 'Plumbing', value: 'Plumbing' },
                        { label: 'HVAC', value: 'HVAC' }]}
                        textField='label'
                        valueField='value'
                      // inputProps={{ readOnly: view }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions style={{ margin: "0 2em 1em 0" }}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                  // onClick={() => {
                  //   //   handleSendRequest();
                  // }}
                  >
                    Confirm
                  </Button>
                  <Button
                    size="large"
                    onClick={() => {
                      setAddDialog({ open: false, reviews: [] });
                    }}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default ProductsPage;
