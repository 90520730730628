import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow,
  IconButton,
  Typography,
  Chip
} from '@material-ui/core'
import Table from '../../components/Table'
import {
  Add,
  Edit,
  Delete,
  ArrowBack,
  QuestionMark,
  ArrowBackIos
} from '@mui/icons-material'
import { toCapitalize } from '../../utils/utils'
import api from '../../libs/api'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import { AuthContext } from '../../libs/contexts/AuthContext'
import { SnackbarContext } from '../../libs/contexts/SnackbarContext'
import { handleApiError } from '../../libs/handleApiError'
import DeleteDialog from '../../components/DeleteDialog'
import FormikSelect from '../../components/FormikSelect'
import Autocomplete1 from '../../components/Autocomplete1'
import Tabs from '../../components/Tabs'

const TimeslotPage = props => {
  let navigate = useNavigate()
  let { id } = useParams()
  const { auth, isLoading } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [timeslot, setTimeslot] = useState([])
  const [dayName, setDayName] = useState('Monday')
  const [dialog, setDialog] = useState({ open: false })
  const [deleteDialog, setDeleteDialog] = useState({ open: false })

  const fetchData = async day => {
    try {
      const { data } = await api.get(`v1/time/day/${day}`)
      setTimeslot(data.filter(item => item.calendarType == id))
      setDayName(day)
      // openSnackbar({ open: true, color: 'success', message: 'Successful' })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleAddSubmit = async values => {
    try {
      const { data } = await api.post(`v1/time`, values)
      fetchData(dayName)
      setDialog({ open: false })
      openSnackbar({
        open: true,
        color: 'success',
        message: 'Successfully Added'
      })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const handleEditSubmit = async values => {
    try {
      const { data } = await api.put(`v1/time`, values)
      fetchData()
      setDialog({ open: false })
      openSnackbar({
        open: true,
        color: 'success',
        message: 'Successfully Updated'
      })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const handleDelete = async (did, dayName) => {
    try {
      const { data } = await api.delete(`v1/time/${did}`)
      fetchData(dayName)
      setDeleteDialog({ open: false })
      openSnackbar({
        open: true,
        color: 'success',
        message: 'Successfully Deleted'
      })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const dayTable = (
    <Table
      title={'Timeslot'}
      columns={[
        {
          field: 'timeSlot',
          title: 'Timeslot (in hours)',
          render: rowData => (
            <div style={{ marginLeft: '2em' }}> {rowData.timeSlot}</div>
          )
        },
        {
          field: 'description',
          title: 'Description'
          // render: rowData => (
          //   <div style={{ marginLeft: '2em' }}> {rowData.timeSlot}</div>
          // )
        },
        {
          field: 'badgeCategory',
          title: 'Badge Category',
          render: rowData => (
            <>
              {rowData.badgeCategory == 'Desirable' ? (
                <Chip
                  label={rowData.badgeCategory}
                  color='primary'
                  style={{ margin: '.2em', backgroundColor: 'green' }}
                />
              ) : rowData.badgeCategory == 'Undesirable' ? (
                <Chip
                  label={rowData.badgeCategory}
                  color='primary'
                  style={{ margin: '.2em', backgroundColor: 'orange' }}
                />
              ) : (
                <Chip
                  label={rowData.badgeCategory}
                  color='primary'
                  style={{ margin: '.2em', backgroundColor: 'red' }}
                />
              )}
            </>
          )
        }
      ]}
      actions={[
        rowData => ({
          icon: () => <Delete />,
          tooltip: 'Delete',
          onClick: (event, rowData) => {
            setDeleteDialog({
              open: true,
              handleDelete: () => handleDelete(rowData.id, rowData.dayName),
              message: `Are you sure you want to delete this timeslot ?`
            })
          }
          // disabled: rowData.birthYear < 2000
        })
      ]}
      data={timeslot}
      options={{
        actionsColumnIndex: -1,
        search: false,
        // header: false,
        toolbar: false
      }}
      components={{
        Container: props => <div {...props}></div>
      }}
    />
  )
  useEffect(() => {
    fetchData('Monday')
  }, [id])

  if (!timeslot) {
    isLoading(true)
    return 'Loading...'
  }
  return (
    <>
      {isLoading(false)}
      <DeleteDialog
        dialog={deleteDialog}
        handleCancel={() => setDeleteDialog({ open: false })}
      />
      <Paper style={{ padding: ' 1em 2em 2em 2em' }} elevation={5}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginBottom: '1em' }}
        >
          <Grid item style={{ display: 'flex' }}>
            {/* <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton> */}
            <Typography
              variant='h6'
              style={{ display: 'inline', marginTop: '.5em' }}
            >
              Timeslot List - {id} Hour
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              startIcon={<Add />}
              onClick={() =>
                setDialog({
                  open: true,
                  type: 'Add',
                  dayName: 'Monday',
                  badgeCategory: 'Desirable',
                  timeSlot: '',
                  description: '',
                  calendarType: id
                })
              }
            >
              Add Timeslot
            </Button>
          </Grid>
        </Grid>
        <Tabs
          tabs={[
            ...[
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
              'Sunday'
            ].map((item, index) => ({
              id: index + 1,
              label: item,
              list: dayTable
            }))
          ]}
          fetchData={fetchData}
        />
      </Paper>
      <Dialog
        open={dialog.open}
        onClose={() => {
          setDialog({ open: false })
        }}
        fullWidth={true}
        maxWidth='sm'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        TransitionComponent={Grow}
      >
        <DialogTitle id='alert-dialog-title'>
          {dialog.type} Timeslot
        </DialogTitle>
        <Formik
          validationSchema={yup.object().shape({
            timeSlot: yup
              .string()
              // .min(10,"Mobile is invalid")
              .required('Timeslot is required.')
          })}
          initialValues={{
            ...dialog
          }}
          onSubmit={dialog.type == 'Add' ? handleAddSubmit : handleEditSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <DialogContent style={{ margin: '0 1em 1em 1em' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name='timeSlot'
                        // value={values.mobile}
                        // component={PhoneNumberInput}
                        // setFieldValue={(value) => {
                        //   setFieldValue("mobile", value);
                        // }}
                        label='Timeslot'
                        variant='standard'
                        fullWidth
                        type='number'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name='description'
                        // value={values.mobile}
                        // component={PhoneNumberInput}
                        // setFieldValue={(value) => {
                        //   setFieldValue("mobile", value);
                        // }}
                        label='Description'
                        variant='outlined'
                        fullWidth
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={FormikSelect}
                        label='Day'
                        name='dayName'
                        value={values.dayName}
                        //   multiple
                        onChange={e => {
                          setFieldValue('dayName', e.target.value)
                          // setFieldValue('roofMaterial', e.target.value);
                        }}
                        size='small'
                        options={[
                          'Monday',
                          'Tuesday',
                          'Wednesday',
                          'Thursday',
                          'Friday',
                          'Saturday',
                          'Sunday'
                        ].map(item => {
                          return { label: item, value: item }
                        })}
                        textField='label'
                        valueField='value'
                        // inputProps={{ readOnly: view }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={FormikSelect}
                        label='Day'
                        name='badgeCategory'
                        value={values.badgeCategory}
                        //   multiple
                        onChange={e => {
                          setFieldValue('badgeCategory', e.target.value)
                          // setFieldValue('roofMaterial', e.target.value);
                        }}
                        size='small'
                        options={['Desirable', 'Undesirable', 'Emergency'].map(
                          item => {
                            return { label: item, value: item }
                          }
                        )}
                        textField='label'
                        valueField='value'
                        // inputProps={{ readOnly: view }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions style={{ margin: '0 2em 1em 0' }}>
                  <Button type='submit' color='primary' variant='contained'>
                    Confirm
                  </Button>
                  <Button
                    size='large'
                    onClick={() => {
                      setDialog({ open: false })
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            )
          }}
        </Formik>
      </Dialog>
    </>
  )
}

export default TimeslotPage
