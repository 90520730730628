import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './libs/Routes'
import SnackbarContextProvider from './libs/contexts/SnackbarContext'
import AuthContextProvider from './libs/contexts/AuthContext'
import CustomThemeProvider from './libs/contexts/ThemeProvider'

const App = () => {
  return (
    <>
      <CustomThemeProvider>
        <BrowserRouter>
          <AuthContextProvider>
            <SnackbarContextProvider>
              <Routes />
            </SnackbarContextProvider>
          </AuthContextProvider>
        </BrowserRouter>
      </CustomThemeProvider>
    </>
  )
}

export default App
