import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Select, TextField, FormControl, Checkbox } from '@material-ui/core'
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const filter = createFilterOptions()

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },

  noLabel: {
    marginTop: theme.spacing(3)
  }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const Autocomplete1 = props => {
  const { label, name, options, value, setFieldValue, error } = props
  // const [value, setValue] = React.useState();

  return (
    <FormControl fullWidth variant='standard' size='small'>
      <Autocomplete
        value={value}
        multiple
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // setValue({
            //   title: newValue
            // });
            setFieldValue(name, {
              title: newValue
            })
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            // setValue({
            //   title: newValue.inputValue
            // });
            setFieldValue(name, {
              title: newValue.inputValue
            })
          } else {
            // setValue(newValue);
            setFieldValue(name, newValue)
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`
            })
          }
          return filtered
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id='free-solo-with-text-demo'
        options={options}
        getOptionLabel={option => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue
          }
          // Regular option
          return option.title
        }}
        renderOption={option => option.title}
        // renderOption={(option, { selected }) => (
        //   <React.Fragment>
        //     <Checkbox
        //       icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        //       checkedIcon={<CheckBoxIcon fontSize="small" />}
        //       style={{ marginRight: 8 }}
        //       checked={selected}
        //     />
        //     {option.title}
        //   </React.Fragment>
        // )}
        // style={{ width: 300 }}
        // freeSolo
        renderInput={params => (
          <TextField {...params} label={label} variant='standard' {...error} />
        )}
        renderTags={
          (value, getTagProps) => <></>
          // value.map((option, index) => (
          //   <Chip
          //     variant="outlined"
          //     label={option.title}
          //     size="small"
          //     {...getTagProps({ index })}
          //   />
          // ))
        }
      />
    </FormControl>
  )
}

export default Autocomplete1
