import React from 'react'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff'
    },
    secondary: {
      //   light: '#ff7961',
      // main: '#f44336',
      //   dark: '#ba000d',
      light: '#d8f2ff',
      main: '#7ccef6',
      dark: '#018acd',
      //   main: 'linear-gradient(to bottom,#7ccef6 0%,#80f19f 100%)',
      contrastText: '#fff'
    }
  }
})

export default function CustomThemeProvider (props) {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}
