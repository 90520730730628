import React, { useState, createContext } from 'react'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

export const SnackbarContext = createContext()

const SnackbarContextProvider = props => {
  const [toast, setToast] = useState({
    open: false,
    color: 'success',
    message: 'Success'
  })
  const openSnackbar = ({ open, color, message }) => {
    setToast({ open: open, color: color, message: message })
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setToast({ open: false, color: toast.color, message: '' })
  }

  return (
    <>
      <SnackbarContext.Provider value={{ openSnackbar: openSnackbar }}>
        {props.children}
        <Snackbar
          open={toast.open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            elevation={6}
            variant='filled'
            onClose={handleClose}
            severity={toast.color}
            sx={{ width: '100%' }}
          >
            {toast.message}
          </Alert>
        </Snackbar>
      </SnackbarContext.Provider>
    </>
  )
}

export default SnackbarContextProvider
