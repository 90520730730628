import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker
// } from '@material-ui/pickers'
// import 'date-fns'
// import DateFnsUtils from '@date-io/date-fns'
import { formatDate } from '../utils/dateUtils'
// import { makeStyles, useTheme } from '@material-ui/core/styles'
// import {
//   shiftPickerDateToTZDate,
//   shiftTzDateToPickerDate
// } from '../utils/Utils'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const FormikDatePicker = props => {
    const { label, field, name, value, setFieldValue, size, view } = props
    return (
        <>
            {view ? (
                <TextField
                    name={name}
                    fullWidth
                    inputProps={{ readOnly: view }}
                    label={label}
                    variant='outlined'
                    value={formatDate(new Date(value))}
                />
            ) : (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DesktopDatePicker']} fullWidth >
                        <DesktopDatePicker
                            // views={['month', 'day', 'year']}
                            // InputProps={{ readOnly: view }}
                            {...props}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    // variant:'standard'
                                    error: props.error,
                                    helperText: props.error ?? null,
                                },
                            }}
                            minDate={dayjs(new Date())}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            )}
        </>
    )
}

export default FormikDatePicker
