import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow
} from '@material-ui/core'
import Table from '../../components/Table'
import { Add } from '@mui/icons-material'
import { toCapitalize } from '../../utils/utils'
import api from '../../libs/api'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import { SnackbarContext } from '../../libs/contexts/SnackbarContext'
import { handleApiError } from '../../libs/handleApiError'

const MaterialMarkupPage = props => {
  let navigate = useNavigate()
  //   let { id } = useParams()
  const { openSnackbar } = useContext(SnackbarContext)
  const [markup, setMarkup] = useState()
  const [addDialog, setAddDialog] = useState({ open: false })

  const fetchData = async values => {
    try {
      const { data } = await api.get(`v1/markup?markuptype=material`)
      setMarkup(data)
    } catch (error) {
      console.log('Err', error.response)
      handleApiError(error, openSnackbar)
    }
  }
  const handleRowAdd = async (newData, resolve) => {
    try {
      const { data } = await api.post(`v1/markup`, {
        ...newData,
        markuptype: 'material'
      })
      resolve()
      openSnackbar({ open: true, color: 'success', message: 'Successfully Added' })
      fetchData()
      //   setAddDialog({ open: false })
    } catch (error) {
      handleApiError(error, openSnackbar)
      resolve()
    }
  }
  const handleRowUpdate = async (newData, oldData, resolve) => {
    try {
      const { data } = await api.put(`v1/markup`, {
        ...newData,
        id: oldData.id
      })
      resolve()
      openSnackbar({
        open: true,
        color: 'success',
        message: 'Successfully Updated'
      })
      fetchData()
      //   setAddDialog({ open: false })
    } catch (error) {
      handleApiError(error, openSnackbar)
      resolve()
    }
  }
  const handleRowDelete = async (newData, resolve) => {
    try {
      const { data } = await api.delete(`v1/markup/${newData.id}`)
      resolve()
      openSnackbar({
        open: true,
        color: 'success',
        message: 'Successfully Deleted'
      })
      fetchData()
      //   setAddDialog({ open: false })
    } catch (error) {
      handleApiError(error, openSnackbar)
      resolve()
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (!markup) {
    return 'Loading...'
  }
  return (
    <>
      <Table
        title={'Material Markup'}
        columns={[
          { field: 'partcostmin', title: 'Minimum Part Cost ' },
          { field: 'partcostmax', title: 'Maximum Part Cost ' },
          { field: 'markupprice', title: 'Markup Price' }
          //     render: (rowData) => (
          //       <img
          //         src={rowData.url}
          //         style={{ width: 50, borderRadius: "50%" }}
          //       />
          //     ),
        ]}
        data={markup}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              handleRowUpdate(newData, oldData, resolve)
            }),
          onRowAdd: newData =>
            new Promise(resolve => {
              handleRowAdd(newData, resolve)
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              handleRowDelete(oldData, resolve)
            })
        }}
        options={{
          actionsColumnIndex: -1
        }}
        // actions={[
        //   {
        //     icon: '+',
        //     tooltip: 'Add Service',
        //     isFreeAction: true,
        //     onClick: () => {
        //       setAddDialog({ open: true, id: plumbings.info.id })
        //     }
        //   }
        // ]}
      />
      <Dialog
        open={addDialog.open}
        onClose={() => {
          setAddDialog({ open: false })
        }}
        fullWidth={true}
        maxWidth='sm'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        TransitionComponent={Grow}
      >
        <DialogTitle id='alert-dialog-title'>Add</DialogTitle>
        <Formik
          validationSchema={yup.object().shape({
            name: yup
              .string()
              // .min(10,"Mobile is invalid")
              .required('Name is required.')
          })}
          initialValues={{
            id: addDialog.id,
            name: ''
          }}
          //   onSubmit={handleAddSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <DialogContent style={{ margin: '0 1em 1em 1em' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name='name'
                        // value={values.mobile}
                        // component={PhoneNumberInput}
                        // setFieldValue={(value) => {
                        //   setFieldValue("mobile", value);
                        // }}
                        label='Name'
                        variant='standard'
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions style={{ margin: '0 2em 1em 0' }}>
                  <Button
                    type='submit'
                    color='primary'
                    variant='contained'
                    // onClick={() => {
                    //   //   handleSendRequest();
                    // }}
                  >
                    Confirm
                  </Button>
                  <Button
                    size='large'
                    onClick={() => {
                      setAddDialog({ open: false, reviews: [] })
                    }}
                    color='primary'
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            )
          }}
        </Formik>
      </Dialog>
    </>
  )
}

export default MaterialMarkupPage
