import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Grid, Paper, Button, InputAdornment } from '@mui/material'
import GoogleIcon from '@mui/icons-material/Google'
import FacebookIcon from '@mui/icons-material/Facebook'

import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import api from '../../libs/api'
import { SnackbarContext } from '../../libs/contexts/SnackbarContext'
import { handleApiError } from '../../libs/handleApiError'

import { MailOutlineTwoTone, LockTwoTone } from '@mui/icons-material'
import logo from '../../assets/images/igd-logo.png'

import { AuthContext } from '../../libs/contexts/AuthContext'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const Login = props => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)

  const handleSubmit = async values => {
    try {
      const { data } = await api.post(`v1/auth`, values)
      handleSetAuth(data)
      // localStorage.setItem('authToken', `Bearer ${data.authToken}`)
      // localStorage.setItem('data', JSON.stringify(data.data))
      localStorage.setItem('role', 'Admin')
      navigate('/bookings')
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
const [showPass,setShowPass] = useState(false)
console.log(showPass)
  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='center'
        // alignItems="center"
        style={{ height: '100%', paddingTop: '5em' }}
      >
        <Grid item xs={10} md={6} lg={4}>
          <Paper elevation={3} style={{ padding: '1em 1em 2em 1em' }}>
            <div style={{ textAlign: 'center' }}>
              <img
                src={logo}
                alt='igd-plumbing-logo'
                style={{ height: '10em' }}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '1.5em',
                padding: '1em 0em'
              }}
            >
              Login
            </div>
            <Formik
              validationSchema={yup.object().shape({
                authuser: yup
                  .string()
                  // .min(10,"Mobile is invalid")
                  .required('Name is required.'),
                authpassword: yup
                  .string()
                  // .min(10,"Mobile is invalid")
                  .required('Passord is required.')
              })}
              initialValues={{
                authuser: '',
                authpassword: ''
              }}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name='authuser'
                          // value={values.mobile}
                          // component={PhoneNumberInput}
                          // setFieldValue={(value) => {
                          //   setFieldValue("mobile", value);
                          // }}
                          label='Name'
                          variant='standard'
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                <MailOutlineTwoTone />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>{' '}
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name='authpassword'
                          // value={values.mobile}
                          // component={PhoneNumberInput}
                          // setFieldValue={(value) => {
                          //   setFieldValue("mobile", value);
                          // }}
                          type={showPass? "text" : "password"}
                          label='Password'
                          variant='standard'
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                <LockTwoTone />
                              </InputAdornment>
                            ),
                            endAdornment:(
                              <InputAdornment position='end' sx={{cursor:"pointer"}}  onClick={()=>setShowPass(!showPass)}>
                                {showPass ? <VisibilityIcon  /> : <VisibilityOffIcon />}
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {/* <div style={{textAlign: 'center'}}></div> */}
                        <Button
                          type='submit'
                          color='primary'
                          variant='contained'
                          fullWidth
                          style={{ margin: '1em 0' }}
                          // onClick={() => {
                          //   //   handleSendRequest();
                          // }}
                        >
                          Login
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
            {/* <Grid
              container
              direction='row'
              justifyContent='space-evenly'
              // alignItems="center"
              style={{ marginTop: '2em' }}
            >
              <Grid item>
                <Button
                  variant='outlined'
                  startIcon={<GoogleIcon />}
                  fullWidth
                  onClick={() => {
                    navigate('/dashboard')
                  }}
                >
                  Sign In with Google
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='outlined'
                  startIcon={<FacebookIcon />}
                  fullWidth
                  onClick={() => {
                    navigate('/dashboard')
                  }}
                >
                  Sign In with Facebook
                </Button>
              </Grid>
            </Grid> */}
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default Login
