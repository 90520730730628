import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow,
  List,
  ListItem,
  IconButton,
} from '@material-ui/core'
import Table from '../../components/Table'
import { toCapitalize } from '../../utils/utils'
import api from '../../libs/api'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import { Add, Edit, Delete, Close } from '@mui/icons-material'
import FormikSelect from '../FormikSelect'
import CustomFileInput from '../CustomFileInput'

const CustomerForm = (props) => {
  let navigate = useNavigate()
  let { id } = useParams()
  let { values, setFieldValue, formType, ...rest } = props
  let view = formType == 'view' ? true : false
  let edit = formType == 'edit' ? true : false

  return (
    <>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {/* <span className='font-weight-bold text-black-100 '>Product Number</span> */}
            <Field
              name='companyname'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Company Name'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='contactperson'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Contact Person'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='mobile'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Mobile'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='email'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Email'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='status'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Status'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='city'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='City'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='country'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Country'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>

          {view ? null : (
            <Grid item xs={12}>
              <div style={{ marginTop: '2em' }}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={rest.isSubmitting}
                >
                  Save
                </Button>
                <Button
                  color='primary'
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </Form>
    </>
  )
}

export default CustomerForm
