import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow,
  Typography,
  Chip, Box,
  Tooltip
} from "@material-ui/core";
import Table from "../../components/Table";
import { toCapitalize } from "../../utils/utils";
import api from "../../libs/api";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import DeleteDialog from "../../components/DeleteDialog";
import { Add, Edit, Delete } from "@mui/icons-material";
import { AuthContext } from "../../libs/contexts/AuthContext";
import { SnackbarContext } from "../../libs/contexts/SnackbarContext";
import { handleApiError } from "../../libs/handleApiError";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

const Customers = (props) => {
  let navigate = useNavigate();
  let { id } = useParams();
  const { auth, isLoading } = useContext(AuthContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const [customers, setCustomers] = useState();
  const [addDialog, setAddDialog] = useState({ open: false });
  const [deleteDialog, setDeleteDialog] = useState({ open: false });
  const [statusDialog, setStatusDialog] = useState({ open: false });

  const fetchData = async (values) => {
    try {
      const { data } = await api.get(`v1/customers`);
      setCustomers(data);
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };

  const handleAddSubmit = async (values) => {
    try {
      const { data } = await api.post(`v1/admin`, values);
      fetchData();
      setAddDialog({ open: false });
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };

  const handleDelete = async (id) => {
    try {
      const { data } = await api.delete(`v1/customers/${id}`);
      fetchData();
      setDeleteDialog({ open: false });
      openSnackbar({
        open: true,
        color: "success",
        message: "Successfully Deleted",
      });
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };

  const handleStatus = async (id, status) => {
    try {
      const { data } = await api.put(`v1/customers/update-status`, {
        id: id,
        status: status,
      });
      fetchData();
      setStatusDialog({ open: false });
      openSnackbar({
        open: true,
        color: "success",
        message: "Status Changed Successfully",
      });
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // if (!customers) {
  //   isLoading(true)
  //   return 'Loading...'
  // }

  return (
    <>
      {/* {isLoading(false)} */}
      <DeleteDialog
        dialog={deleteDialog}
        handleCancel={() => setDeleteDialog({ open: false })}
      />
      <Paper style={{ padding: "1em 2em 0 2em" }} elevation={5}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: "1em" }}
        >
          <Grid item style={{ display: "flex" }}>
            {/* <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton> */}
            <Typography
              variant="h6"
              style={{ display: "inline", marginTop: ".5em" }}
            >
              Customers
            </Typography>
          </Grid>
          <Grid item>
            {/* <Button
              variant='contained'
              color='primary'
              startIcon={<Add />}
              onClick={() => navigate('/customers/add')}
            >
              Add Customer
            </Button> */}
          </Grid>
        </Grid>
        <Table
          title={"Customers"}
          columns={[
            { field: "name", title: "Name" },
            // { field: 'contactperson', title: 'Contact Person' },
            {
              field: 'mobile', title: 'Mobile',
              render: (rowData) => {
                { console.log(rowData.mobile) }
                const phoneNumber = rowData.mobile
                return <Box sx={{ width: "130px" }} >
                  {phoneNumber ? (
                    <>({phoneNumber.substring(0, 3)}) {phoneNumber.substring(3, 6)}-{phoneNumber.substring(6)}</>
                  ) : null}

                </Box>
              }
            },
            { field: 'authemail', title: 'Email' },
            {
              field: 'address1', title: 'Address',
              render: (rowData) => {
                return <Tooltip title={rowData.address1}>
                  <Typography fontSize="large" noWrap style={{ textOverflow: "ellipsis", width: '130px', cursor: "pointer" }}>
                    {rowData.address1}
                  </Typography>
                </Tooltip>
              }
            },
            // { field: 'city', title: 'City' },
            { field: "state", title: "State" },
            { field: "zipcode", title: "Zipcode" },
            {
              field: "status",
              title: "Status",
              render: (rowData) =>
                rowData.status == "Inactive" ? (
                  <Chip
                    label="Inactive"
                    style={{ backgroundColor: "red", color: "#fff" }}
                  />
                ) : (
                  <Chip
                    label="Active"
                    style={{ backgroundColor: "green", color: "#fff" }}
                  />
                ),
            },
            //     render: (rowData) => (
            //       <img
            //         src={rowData.url}
            //         style={{ width: 50, borderRadius: "50%" }}
            //       />
            //     ),
          ]}
          data={customers}
          actions={[
            // {
            //   icon: () => <Add />,
            //   tooltip: 'Add Customers',
            //   isFreeAction: true,
            //   onClick: () => {
            //     //   setAddDialog({ open: true })
            //     navigate('/customers/add')
            //   }
            // },
            // {
            //   icon: () => <Edit />,
            //   tooltip: 'Edit',
            //   onClick: (event, rowData) => {
            //     navigate(`/customers/edit/${rowData.id}`)
            //   }
            // },
            {
              icon: () => <AssignmentIndIcon />,
              tooltip: "Change Status",
              onClick: (event, rowData) => {
                setStatusDialog({
                  open: true,
                  message:
                    rowData.status == "Inactive"
                      ? "Are you sure to Activate the customer account ?"
                      : "Are you sure to Deactivate the customer account ?",
                  handleStatus: () =>
                    handleStatus(
                      rowData.id,
                      rowData.status == "Inactive" ? "Active" : "Inactive"
                    ),
                });
              },
            },
            // rowData => ({
            //   icon: () => <Delete />,
            //   tooltip: 'Delete',
            //   onClick: (event, rowData) => {
            //     setDeleteDialog({
            //       open: true,
            //       handleDelete: () => handleDelete(rowData.id),
            //       message: `Are you sure you want to delete?`
            //     })
            //   }
            //   // disabled: rowData.birthYear < 2000
            // })
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            // header: false,
            toolbar: false,
          }}
          components={{
            Container: (props) => <div {...props}></div>,
          }}
        />
      </Paper>
      <Dialog
        open={addDialog.open}
        onClose={() => {
          setAddDialog({ open: false });
        }}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Grow}
      >
        <DialogTitle id="alert-dialog-title">Add</DialogTitle>
        <Formik
          validationSchema={yup.object().shape({
            name: yup
              .string()
              // .min(10,"Mobile is invalid")
              .required("Name is required."),
          })}
          initialValues={{
            // id: addDialog.id,
            name: "",
          }}
          onSubmit={handleAddSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <DialogContent style={{ margin: "0 1em 1em 1em" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="name"
                        // value={values.mobile}
                        // component={PhoneNumberInput}
                        // setFieldValue={(value) => {
                        //   setFieldValue("mobile", value);
                        // }}
                        label="Name"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions style={{ margin: "0 2em 1em 0" }}>
                  <Button
                    type='submit'
                    color='primary'
                    variant='contained'
                  // onClick={() => {
                  //   //   handleSendRequest();
                  // }}
                  >
                    Confirm
                  </Button>
                  <Button
                    size="large"
                    onClick={() => {
                      setAddDialog({ open: false, reviews: [] });
                    }}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
      <Dialog
        open={statusDialog.open}
        onClose={() => {
          setStatusDialog({ open: false });
        }}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Grow}
      >
        <DialogContent
          style={{
            margin: "1me 1em 1em 1em",
            paddingBottom: "1em",
            fontWeight: "bold",
            fontSize: "1.2em",
          }}
        >
          {statusDialog.message}
        </DialogContent>
        <DialogActions style={{ margin: "0 2em 1em 0" }}>
          <Button
            type="submit"
            color="primary"
            // style={{ backgroundColor: 'red' }}
            variant="contained"
            onClick={statusDialog.handleStatus}
            size="small"
          >
            Confirm
          </Button>
          <Button size="small" onClick={() => setStatusDialog({ open: false })}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Customers;
