import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import { Grid, Menu, MenuItem } from '@material-ui/core'
import {
  Lock,
  Inbox,
  Mail,
  Handyman,
  Build,
  Cached,
  Receipt,
  Plumbing,
  ExpandLess,
  ExpandMore,
  PlayArrow,
  Air,
  Dashboard,
  Inventory2,
  HomeRepairService,
  LabelImportant,
  Engineering,
  PowerSettingsNew,
  AccountCircle,
  AccountTree,
  Hvac,
  Timelapse,
  MenuBook,
  Sync,
  Description,
  LocalShipping,
  Adjust,
  Badge
} from '@mui/icons-material'
import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom'
import { AuthContext } from '../libs/contexts/AuthContext'
import LinearLoader from '../components/loader/LinearLoader'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    // backgroundColor: '#7ccff3 !important',
    backgroundColor: '#fff',
    color: '#000',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    // background: 'linear-gradient(to bottom,#7ccef6 0%,#80f19f 100%)'
    color: '#fff',
    background: 'black !important'
    //#000000e0
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  content1: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  content2: {
    padding: theme.spacing(3)
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}))

export default function PersistentDrawerLeft () {
  const classes = useStyles()
  const theme = useTheme()
  let navigate = useNavigate()
  const { loading, openLogoutDialog } = React.useContext(AuthContext)
  const [open, setOpen] = React.useState(false)
  const [sublistOpen, setSublistOpen] = React.useState('')

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                edge='start'
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>

              <div
                style={{
                  display: 'inline',
                  paddingLeft: '1em',
                  position: 'absolute',
                  top: '1em'
                }}
              >
                <Typography variant='h6' noWrap component='span'>
                  Customer Portal
                </Typography>
              </div>
            </Grid>
            <Grid item lg>
              <div style={{ textAlign: 'end' }}>
                <IconButton
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  // onClick={handleMenu}
                  onClick={openLogoutDialog}
                  color='inherit'
                  style={{ color: 'red' }}
                >
                  <PowerSettingsNew />
                </IconButton>
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={anchorEl}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                </Menu>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography
            variant='h5'
            noWrap
            // component='div'
            style={{ fontWeight: 'bold' }}
          >
            IGD Plumbing and Air
          </Typography>
          {/* <img
            src={header}
            alt='Twelve Springs'
            style={{
              maxWidth: '-webkit-fill-available',
              padding: '20px',
              marginLeft: '45px',
            }}
          /> */}
          <IconButton onClick={handleDrawerClose} style={{ color: '#fff' }}>
            <Adjust />
            {/* {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )} */}
          </IconButton>
        </div>
        <Divider />
        <List>
          {[
            {
              label: 'My Bookings',
              path: '/my-bookings',
              icon: <MenuBook />
            },
            {
              label: 'My Profile',
              path: '/my-profile',
              icon: <AccountCircle />
            },
            {
              label: 'Change Password',
              path: '/change-password',
              icon: <Lock />
            }
          ].map(item => (
            <ListItem
              button
              // key={text}
              onClick={() => {
                navigate(item.path)
              }}
            >
              <ListItemIcon style={{ color: 'white' }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>

        {/* <Divider /> */}
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <Inbox /> : <Mail />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <main
        className={clsx(classes.content1, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.drawerHeader} />
        {loading && <LinearLoader />}
        <div className={classes.content2}>
          <Outlet />{' '}
        </div>
      </main>
    </div>
  )
}
