import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow,
  IconButton,
  Typography,
  Chip,
} from "@material-ui/core";
import Table from "../../components/Table";
import {
  Add,
  Edit,
  Delete,
  ArrowBack,
  QuestionMark,
  ArrowBackIos,
} from "@mui/icons-material";
import { toCapitalize } from "../../utils/utils";
import api from "../../libs/api";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import { AuthContext } from "../../libs/contexts/AuthContext";
import { SnackbarContext } from "../../libs/contexts/SnackbarContext";
import { handleApiError } from "../../libs/handleApiError";
import DeleteDialog from "../../components/DeleteDialog";
import FormikSelect from "../../components/FormikSelect";
import Autocomplete1 from "../../components/Autocomplete1";

const QuestionsPage = (props) => {
  let navigate = useNavigate();
  let { id } = useParams();
  const { auth, isLoading } = useContext(AuthContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const [questions, setQuestions] = useState();
  const [dialog, setDialog] = useState({ open: false });
  const [deleteDialog, setDeleteDialog] = useState({ open: false });

  const fetchData = async (values) => {
    try {
      const { data } = await api.get(`v1/categories/question/${id}`);
      setQuestions(data);
      // openSnackbar({ open: true, color: 'success', message: 'Successful' })
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };

  const handleAddSubmit = async (values) => {
    try {
      const { data } = await api.post(`v1/categories/questions`, {
        ...values,
        options: values.options.map((item) => item.inputValue),
      });
      fetchData();
      setDialog({ open: false });
      openSnackbar({
        open: true,
        color: "success",
        message: "Successfully Added",
      });
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };
  const handleEditSubmit = async (values) => {
    try {
      const { data } = await api.put(`v1/categories/questions`, {...values,
        options: values.options.map((item) => item.inputValue),
        categoryid: id,
      });
      fetchData();
      setDialog({ open: false });
      openSnackbar({
        open: true,
        color: "success",
        message: "Successfully Updated",
      });
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };
  const handleDelete = async (qid) => {
    try {
      const { data } = await api.delete(`v1/categories/question/${id}/${qid}`);
      fetchData();
      setDeleteDialog({ open: false });
      openSnackbar({
        open: true,
        color: "success",
        message: "Successfully Deleted",
      });
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!questions) {
    isLoading(true);
    return "Loading...";
  }
  return (
    <>
      {isLoading(false)}
      <DeleteDialog
        dialog={deleteDialog}
        handleCancel={() => setDeleteDialog({ open: false })}
      />
      <Paper style={{ padding: " 1em 2em 2em 2em" }} elevation={5}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: "1em" }}
        >
          <Grid item style={{ display: "flex" }}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
            <Typography
              variant="h6"
              style={{ display: "inline", marginTop: ".5em" }}
            >
              Questions List
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() =>
                setDialog({
                  open: true,
                  type: "Add",
                  categoryid: id,
                  questiontext: "",
                  options: [],
                })
              }
            >
              Add Questions
            </Button>
          </Grid>
        </Grid>
        <Table
          title={"HVAC"}
          columns={[
            { field: "questiontext", title: "Question Text" },
            {
              field: "options",
              title: "Options",
              render: (rowData) =>
                rowData.options.map((item) => (
                  <>
                    <Chip
                      label={item}
                      color="primary"
                      style={{ margin: ".2em" }}
                    />
                    <br />
                  </>
                )),
            },
          ]}
          data={questions}
          // parentChildData={(row, rows) => rows.find(a => a.id === row.parent)}
          actions={[
            {
              icon: () => <Edit />,
              tooltip: 'Edit',
              onClick: (event, rowData) => {
                setDialog({ open: true, type: 'Edit', ...rowData })
              }
              // disabled: rowData.birthYear < 2000
            },
            (rowData) => ({
              icon: () => <Delete />,
              tooltip: "Delete",
              onClick: (event, rowData) => {
                setDeleteDialog({
                  open: true,
                  handleDelete: () => handleDelete(rowData.id),
                  message: `Are you sure you want to delete?`,
                });
              },
              // disabled: rowData.birthYear < 2000
            }),
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            // header: false,
            toolbar: false,
          }}
          components={{
            Container: (props) => <div {...props}></div>,
          }}
        />
      </Paper>
      <Dialog
        open={dialog.open}
        onClose={() => {
          setDialog({ open: false });
        }}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Grow}
      >
        <DialogTitle id="alert-dialog-title">
          {dialog.type} Questions
        </DialogTitle>
        <Formik
          validationSchema={yup.object().shape({
            questiontext: yup
              .string()
              // .min(10,"Mobile is invalid")
              .required("Name is required."),
            options: yup.array().min(2, "Minimum 2 options required"),
          })}
          initialValues={{
            ...dialog,
            options: dialog.options
              ? dialog.options.map((item) => ({ inputValue: item }))
              : [],
          }}
          onSubmit={dialog.type == "Add" ? handleAddSubmit : handleEditSubmit}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <DialogContent style={{ margin: "0 1em 1em 1em" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="questiontext"
                        // value={values.mobile}
                        // component={PhoneNumberInput}
                        // setFieldValue={(value) => {
                        //   setFieldValue("mobile", value);
                        // }}
                        label="Questions"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete1
                        name="options"
                        label="Options"
                        options={[]}
                        fullWidth
                        value={values.options}
                        setFieldValue={setFieldValue}
                        error={{
                          error: errors["options"],
                          helperText: errors["options"],
                         }}
                      />
                      {values.options.map((item, index) => (
                        <>
                          <Chip
                            // size='small'
                            label={item.inputValue}
                            onDelete={() => {
                              setFieldValue(
                                "options",
                                values.options.filter(
                                  (dItem, dIndex) => dIndex != index
                                )
                              );
                            }}
                            color="primary"
                            style={{ marginTop: ".4em" }}
                          />
                          <br />
                        </>
                      ))}
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Field
                        component={FormikSelect}
                        label='Options'
                        name='parent'
                        value={values.parent}
                        //   multiple
                        onChange={e => {
                          setFieldValue('parent', e.target.value)
                          // setFieldValue('roofMaterial', e.target.value);
                        }}
                        size='small'
                        options={categories.hvac.map(item => {
                          return { label: item.name, value: item.id }
                        })}
                        textField='label'
                        valueField='value'
                        // inputProps={{ readOnly: view }}
                      />
                    </Grid> */}
                  </Grid>
                </DialogContent>
                <DialogActions style={{ margin: "0 2em 1em 0" }}>
                  <Button type="submit" color="primary" variant="contained">
                    Confirm
                  </Button>
                  <Button
                    size="large"
                    onClick={() => {
                      setDialog({ open: false });
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default QuestionsPage;
