import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow,
  List,
  ListItem,
  IconButton
} from '@material-ui/core'
import Table from '../../components/Table'
import { toCapitalize } from '../../utils/utils'
import api from '../../libs/api'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import { Add, Edit, Delete, Close } from '@mui/icons-material'
import FormikSelect from '../FormikSelect'
import CustomFileInput from '../CustomFileInput'

const ProductForm = props => {
  let navigate = useNavigate()
  let { id } = useParams()
  let { values, setFieldValue, formType, handleUploadFile, ...rest } = props
  let view = formType == 'view' ? true : false
  let edit = formType == 'edit' ? true : false

  return (
    <>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {/* <span className='font-weight-bold text-black-100 '>Product Number</span> */}
            <Field
              name='productnumber'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Product Number'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='productmodel'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Model Number'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='headertext'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Header Text'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='subheadertext'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Sub Header Text'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid> 
          <Grid item xs={12} md={6}>
            <Field
              name='description'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Description'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              component={FormikSelect}
              label='Category Level 1'
              name='categorlevel1id'
              value={values.categorlevel1id}
              //   multiple
              onChange={e => {
                setFieldValue('categorlevel1id', e.target.value)
                setFieldValue('categorlevel2id', '')
                setFieldValue('categorlevel3id', '')
                setFieldValue(
                  'categoryOptionsLevel2',
                  values.allCategories.filter(c => c.parent == e.target.value)
                )
              }}
              size='small'
              options={values.categoryOptionsLevel1.map(item => {
                return { label: item.name, value: item.id }
              })}
              textField='label'
              valueField='value'
              // inputProps={{ readOnly: view }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              component={FormikSelect}
              label='Category Level 2'
              name='categorlevel2id'
              value={values.categorlevel2id}
              //   multiple
              onChange={e => {
                setFieldValue('categorlevel2id', e.target.value)
                setFieldValue('categorlevel3id', '')
                setFieldValue(
                  'categoryOptionsLevel3',
                  values.allCategories.filter(c => c.parent == e.target.value)
                )
              }}
              size='small'
              options={values.categoryOptionsLevel2.map(item => {
                return { label: item.name, value: item.id }
              })}
              textField='label'
              valueField='value'
              // inputProps={{ readOnly: view }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              component={FormikSelect}
              label='Category Level 3'
              name='categorlevel3id'
              value={values.categorlevel3id}
              //   multiple
              onChange={e => {
                setFieldValue('categorlevel3id', e.target.value)
                // setFieldValue(
                //   'categoryOptionsLevel4',
                //   values.allCategories.filter(c => c.parent == e.target.value)
                // )
                setFieldValue('categorlevelname', e.target.label)
              }}
              size='small'
              options={values.categoryOptionsLevel3.map(item => {
                return { label: item.name, value: item.id }
              })}
              textField='label'
              valueField='value'
              // inputProps={{ readOnly: view }}
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Field
              component={FormikSelect}
              label='Category Level 4'
              name='categorlevel4id'
              value={values.categorlevel4id}
              //   multiple
              onChange={e => {
                setFieldValue('categorlevel4id', e.target.value)
                setFieldValue('categorlevelname', e.target.label)
              }}
              size='small'
              options={values.categoryOptionsLevel4.map(item => {
                return { label: item.name, value: item.id }
              })}
              textField='label'
              valueField='value'
              // inputProps={{ readOnly: view }}
            />
          </Grid> */}
        
          {/* <Grid item xs={12} md={6}>
            <Field
              name='materialcost'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Material Cost'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='materialtext'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Material Text'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='materialmarkup'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Material Markup'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='labourhours'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Labour Hours'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Field
              name='productprices'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Product Price'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='labourhours'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Labour Hours'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='itemWarrantyTerms'
              component={TextField}
              //   setFieldValue={setFieldValue}
              label='Item Warranty Terms'
              fullWidth
              inputProps={{ readOnly: view }}
              size='small'
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <div style={{ fontSize: '1.2em', color: 'grey' }}>
              Product Image
            </div>
            {!view && (
              <div style={{ display: 'inline', marginRight: '2em' }}>
                <CustomFileInput
                  // files={values.locationPictures}
                  file={[]}
                  onChange={async e => {
                    let list = []
                    for (let file of e.target.files) {
                      list.push(file)
                    }
                    console.log('list', list[0])
                    const data = await handleUploadFile(
                      list[0],
                      setFieldValue,
                      values
                    )
                    setFieldValue(
                      'files',
                      values.files ? [...values.files, data] : [data]
                    )
                    // setFieldValue('productBinaryFile', list[0])
                  }}
                  // multiple={true}
                  multiple={false}
                  // accept="image/*,video/mp4,video/x-m4v,video/*"
                  accept='image/*'
                />
              </div>
            )}
            -{console.log('Image ', values.files)}
            {/* {values.productBinaryFile && (
              <span style={{ fontSize: '1em', fontWeight: 'bold' }}>
                &nbsp;&nbsp; - &nbsp;&nbsp;
                {values.productBinaryFile.name}
              </span>
            )} */}
            <List component='div' style={{ display: 'inline-grid' }}>
              {!values.files || !values.files.length ? (
                <ListItem
                  style={{ margin: '.5em 0 0 2em' }}
                  // className='font-size-md px-5 text-primary d-flex justify-content-between align-items-center'
                >
                  <div>No Files Available</div>
                </ListItem>
              ) : (
                values.files.map((item, index) => (
                  <>
                    <ListItem
                      style={{ margin: '0 0 0 2em', padding: '0' }}
                      // className='font-size-md ml-4 text-primary d-flex justify-content-between align-items-center '
                      key={index}
                    >
                      <a
                        href={item.valueUrl}
                        target='_blank'
                        // onClick={() => {
                        //   setImageDialog(item.url)
                        // }}
                        className='bg-neutral-warning text-warning font-size-md font-weight-bold p-2 w-100 rounded-sm'
                      >
                        <div>File {index + 1}</div>
                      </a>

                      {view ? null : (
                        <IconButton
                          style={{ marginLeft: '3em', padding: '0' }}
                          // className='btn-neutral-danger font-weight-bold rounded-sm shadow-none hover-scale-sm border-0 p-2 m-0  align-items-center justify-content-center'
                          onClick={() => {
                            // setDeleteDialog({
                            //   open: true,
                            //   id: item.id,
                            //   message: `Are you sure you want to delete ${item.leadName} Lead?`
                            // });
                            setFieldValue(
                              'files',
                              values.files.filter((pic, ind) => ind != index)
                            )
                          }}
                        >
                          <Close />
                        </IconButton>
                      )}
                    </ListItem>
                  </>
                ))
              )}
            </List>
          </Grid>
          {view ? null : (
            <Grid item xs={12}>
              <div style={{ marginTop: '2em' }}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={rest.isSubmitting}
                >
                  Save
                </Button>
                <Button
                  color='primary'
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </Form>
    </>
  )
}

export default ProductForm
