import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow,
  Typography,
  IconButton
} from '@material-ui/core'
import Table from '../../components/Table'
import { toCapitalize } from '../../utils/utils'
import api, { toFormData } from '../../libs/api'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import { Add, Edit, Delete, ArrowBack } from '@mui/icons-material'
import ProductForm from '../../components/form/ProductForm'
import { AuthContext } from '../../libs/contexts/AuthContext'
import { SnackbarContext } from '../../libs/contexts/SnackbarContext'
import { handleApiError } from '../../libs/handleApiError'
import DeleteDialog from '../../components/DeleteDialog'

const AddProductsPage = props => {
  let navigate = useNavigate()
  let { id } = useParams()
  const { auth, isLoading } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [categories, setCategories] = useState()
  const [addDialog, setAddDialog] = useState({ open: false })

  const fetchInfo = async values => {
    try {
      const { data } = await api.get(`v1/categories`)
      setCategories(data)
      // openSnackbar({ open: true, color: 'success', message: 'Successful' })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleSubmit = async values => {
    try {
      const { data } = await api.post(`v1/products`, values)
      // fetchData()
      // setAddDialog({ open: false })
      navigate(-1)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const handleUploadFile = async (file, values, setFieldValue) => {
    try {
      const { data } = await api.post(
        `v1/booking/file`,
        toFormData({ file: file })
      )
      return data
    } catch (error) {
      // handleApiError(error, setToast)
      handleApiError(error, openSnackbar)
    }
  }

  useEffect(() => {
    fetchInfo()
  }, [])

  if (!categories) {
    isLoading(true)
    return 'Loading...'
  }
  return (
    <>
      {isLoading(false)}
      <Paper style={{ padding: '2em' }} elevation={5}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginBottom: '1em' }}
        >
          <Grid item style={{ display: 'flex' }}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
            <Typography
              variant='h6'
              style={{
                display: 'inline',
                marginTop: '.4em'
              }}
            >
              Add Product
            </Typography>{' '}
          </Grid>
          <Grid item>
            {/* <Button
              variant='contained'
              color='secondary'
              startIcon={<Add />}
              onClick={() => {}}
            >
              Add Questions
            </Button> */}
          </Grid>
        </Grid>
        <Formik
          children={props => (
            <ProductForm
              {...props}
              formType='add'
              handleUploadFile={handleUploadFile}
            />
          )}
          // enableReinitialize
          validationSchema={yup.object({
            productnumber: yup.string().required('Required*'),
            productmodel: yup.string().required('Required*'),
            categorlevel1id:yup.string().required('Required*'),
            categorlevel2id:yup.string().required('Required*'),
            categorlevel3id:yup.string().required('Required*'),
            // materialcost: yup
            //   .number()
            //   .nullable()
            //   .typeError('Must be number')
            //   .required('Required*'),
            labourhours: yup
              .number()
              .nullable()
              .typeError('Must be number')
              .required('Required*'),
            productprices: yup
              .number()
              .nullable()
              .typeError('Must be number')
              .required('Required*')
            // email: yup
            //   .string()
            //   .email('Invalid Email address')
            //   .required('Email is required*')
          })}
          initialValues={{
            productnumber: '',
            productmodel: '',
            headertext: '',
            subheadertext: '',
            materialcost: null,
            materialtext: '',
            materialmarkup: '',
            labourhours: null,
            productprices: null,
            categorlevel1id: '',
            categorlevel2id: '',
            categorlevel3id: '',
            categorlevel4id: '',
            categorlevelname: '',
            productBinaryFile: null,
            allCategories: categories,
            categoryOptionsLevel1: categories.filter(
              c => (c.name == 'Plumbing' || c.name == 'HVAC') && !c.parent
            ),
            categoryOptionsLevel2: [],
            categoryOptionsLevel3: [],
            categoryOptionsLevel4: [],
            descriptions:'',
            itemWarrantyTerms:''
          }}
          onSubmit={handleSubmit}
        />
      </Paper>
    </>
  )
}

export default AddProductsPage
