import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grow
} from '@material-ui/core'

const DeleteDialog = ({ dialog, handleDelete, handleCancel }) => {
  return (
    <>
      <Dialog
        open={dialog.open}
        onClose={handleCancel}
        fullWidth={false}
        maxWidth='sm'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        TransitionComponent={Grow}
      >
        {/* <DialogTitle id='alert-dialog-title'>LogOut</DialogTitle> */}
        <DialogContent
          style={{
            margin: '1me 1em 1em 1em',
            paddingBottom: '1em',
            fontWeight: 'bold',
            fontSize: '1.2em'
          }}
        >
          {dialog.message}
        </DialogContent>
        <DialogActions style={{ margin: '0 2em 1em 0' }}>
          <Button
            type='submit'
            color='primary'
            style={{ backgroundColor: 'red' }}
            variant='contained'
            onClick={dialog.handleDelete}
            size='small'
          >
            Delete
          </Button>
          <Button
            size='small'
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteDialog
