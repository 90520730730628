import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Grid,
  Typography,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow
} from '@material-ui/core'
import Table from '../../components/Table'
import { Add, Edit, Delete, ArrowBack, Storage } from '@mui/icons-material'
import { toCapitalize } from '../../utils/utils'
import api from '../../libs/api'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import { AuthContext } from '../../libs/contexts/AuthContext'
import { SnackbarContext } from '../../libs/contexts/SnackbarContext'
import { handleApiError } from '../../libs/handleApiError'
import DeleteDialog from '../../components/DeleteDialog'
import FormikSelect from '../../components/FormikSelect'

const PlumbingPage = props => {
  let navigate = useNavigate()
  let { id } = useParams()
  const { auth, isLoading } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [categories, setCategories] = useState()
  const [dialog, setDialog] = useState({ open: false })
  const [deleteDialog, setDeleteDialog] = useState({ open: false })

  const fetchData = async values => {
    try {
      const { data } = await api.get(`v1/categories`)
      let pid = data.find(item => item.name == 'Plumbing').id
      let cid = data.find(
        item => item.name == toCapitalize(id) && item.parent == pid
      ).id
      setCategories({
        data: data,
        plumbingId: cid,
        plumbing: data.filter(item => item.parent == cid)
      })
      // openSnackbar({ open: true, color: 'success', message: 'Successful' })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleAddSubmit = async values => {
    try {
      const { data } = await api.post(`v1/categories`, values)
      fetchData()
      setDialog({ open: false })
      openSnackbar({
        open: true,
        color: 'success',
        message: 'Successfully Added'
      })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const handleEditSubmit = async values => {
    try {
      const { data } = await api.put(`v1/categories`, values)
      fetchData()
      setDialog({ open: false })
      openSnackbar({
        open: true,
        color: 'success',
        message: 'Successfully Updated'
      })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const handleDelete = async id => {
    try {
      const { data } = await api.delete(`v1/categories/${id}`)
      fetchData()
      setDeleteDialog({ open: false })
      openSnackbar({
        open: true,
        color: 'success',
        message: 'Successfully Deleted'
      })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  useEffect(() => {
    fetchData()
  }, [id])

  if (!categories) {
    isLoading(true)
    return 'Loading...'
  }
  return (
    <>
      {isLoading(false)}
      <DeleteDialog
        dialog={deleteDialog}
        handleCancel={() => setDeleteDialog({ open: false })}
      />
      <Paper style={{ padding: '1em 2em 0 2em' }} elevation={5}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginBottom: '1em' }}
        >
          <Grid item style={{ display: 'flex' }}>
            {/* <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton> */}
            <Typography
              variant='h6'
              style={{ display: 'inline', marginTop: '.5em' }}
            >
              Plumbing - {toCapitalize(id)}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              startIcon={<Add />}
              onClick={() =>
                setDialog({
                  open: true,
                  type: 'Add',
                  id: null,
                  parent: categories.plumbingId
                })
              }
            >
              Add Main Category
            </Button>
          </Grid>
        </Grid>
        <Table
          title={'Plumbing'}
          columns={[
            { field: 'name', title: 'Category Name' }
            //   { field: 'description', title: 'Description' }
            //     render: (rowData) => (
            //       <img
            //         src={rowData.url}
            //         style={{ width: 50, borderRadius: "50%" }}
            //       />
            //     ),
          ]}
          data={categories.plumbing}
          // parentChildData={(row, rows) => rows.find(a => a.id === row.parent)}
          actions={[
            {
              icon: () => (
                <Button variant='contained' color='primary' startIcon={<Add />}>
                  Add Main Category
                </Button>
              ),
              // tooltip: 'Add Main Categories',
              isFreeAction: true,
              onClick: (e, rowData) => {
                setDialog({
                  open: true,
                  type: 'Add',
                  id: null,
                  parent: categories.plumbingId
                })
              }
            },
            // rowData => ({
            //   icon: () => <Add />,
            //   tooltip: 'Add',
            //   onClick: (event, rowData) => {
            //     setDialog({
            //       open: true,
            //       type: 'Add',
            //       id: null,
            //       parent: rowData.id
            //     })
            //   }
            //   // disabled: rowData.birthYear < 2000
            // }),
            {
              icon: () => <Storage />,
              tooltip: 'Add Questions',
              onClick: (event, rowData) => {
                navigate(`/questions/${rowData.id}`)
              }
              // disabled: rowData.birthYear < 2000
            },
            {
              icon: () => <Edit />,
              tooltip: 'Edit',
              onClick: (event, rowData) => {
                setDialog({ open: true, type: 'Edit', ...rowData })
              }
              // disabled: rowData.birthYear < 2000
            },
            rowData => ({
              icon: () => <Delete />,
              tooltip: 'Delete',
              onClick: (event, rowData) => {
                setDeleteDialog({
                  open: true,
                  handleDelete: () => handleDelete(rowData.id),
                  message: `Are you sure you want to delete ${rowData.name}?`
                })
              }
              // disabled: rowData.birthYear < 2000
            })
          ]}
          // detailPanel={[
          //   {
          //     tooltip: 'Show Sub Category',
          //     render: rowData => {
          //       return (
          //         <div>
          //           <Table
          //             title={'Plumbing'}
          //             columns={[
          //               {
          //                 field: 'name',
          //                 title: 'Category Name',
          //                 render: rowData => (
          //                   <div style={{ paddingLeft: '6em' }}>
          //                     {rowData.name}
          //                   </div>
          //                 )
          //               },
          //               { field: 'description', title: 'Description' }
          //             ]}
          //             data={categories.data.filter(
          //               item => rowData.id == item.parent
          //             )}
          //             // parentChildData={(row, rows) => rows.find(a => a.id === row.parent)}
          //             actions={[
          //               {
          //                 icon: () => <Storage />,
          //                 tooltip: 'Add Questions',
          //                 onClick: (event, rowData) => {
          //                   navigate(`/questions/${rowData.id}`)
          //                 }
          //                 // disabled: rowData.birthYear < 2000
          //               },
          //               {
          //                 icon: () => <Edit />,
          //                 tooltip: 'Edit',
          //                 onClick: (event, rowData) => {
          //                   setDialog({ open: true, type: 'Edit2', ...rowData })
          //                 }
          //                 // disabled: rowData.birthYear < 2000
          //               },
          //               rowData => ({
          //                 icon: () => <Delete />,
          //                 tooltip: 'Delete',
          //                 onClick: (event, rowData) => {
          //                   setDeleteDialog({
          //                     open: true,
          //                     handleDelete: () => handleDelete(rowData.id),
          //                     message: `Are you sure you want to delete ${rowData.name}?`
          //                   })
          //                 }
          //                 // disabled: rowData.birthYear < 2000
          //               })
          //             ]}
          //             options={{
          //               actionsColumnIndex: -1,
          //               header: false,
          //               toolbar: false,
          //               paging: false
          //             }}
          //             components={{
          //               Container: props => <div {...props}></div>
          //             }}
          //           />
          //         </div>
          //       )
          //     }
          //   }
          // ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            // header: false,
            toolbar: false
          }}
          components={{
            Container: props => <div {...props}></div>
          }}
          // onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
      </Paper>
      <Dialog
        open={dialog.open}
        onClose={() => {
          setDialog({ open: false })
        }}
        fullWidth={true}
        maxWidth='sm'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        TransitionComponent={Grow}
      >
        <DialogTitle id='alert-dialog-title'>
          {dialog.type == 'Edit2' ? 'Edit' : dialog.type} Category
        </DialogTitle>
        <Formik
          validationSchema={yup.object().shape({
            name: yup
              .string()
              // .min(10,"Mobile is invalid")
              .required('Name is required.')
          })}
          initialValues={{
            id: dialog.id,
            parent: dialog.parent,
            name: ''
          }}
          onSubmit={dialog.type == 'Add' ? handleAddSubmit : handleEditSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <DialogContent style={{ margin: '0 1em 1em 1em' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name='name'
                        // value={values.mobile}
                        // component={PhoneNumberInput}
                        // setFieldValue={(value) => {
                        //   setFieldValue("mobile", value);
                        // }}
                        label='Name'
                        variant='standard'
                        fullWidth
                      />
                    </Grid>
                    {dialog.type == 'Edit2' && (
                      <Grid item xs={12}>
                        <Field
                          component={FormikSelect}
                          label='Parent'
                          name='parent'
                          value={values.parent}
                          //   multiple
                          onChange={e => {
                            setFieldValue('parent', e.target.value)
                            // setFieldValue('roofMaterial', e.target.value);
                          }}
                          size='small'
                          options={categories.plumbing.map(item => {
                            return { label: item.name, value: item.id }
                          })}
                          textField='label'
                          valueField='value'
                          // inputProps={{ readOnly: view }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions style={{ margin: '0 2em 1em 0' }}>
                  <Button type='submit' color='primary' variant='contained'>
                    Confirm
                  </Button>
                  <Button
                    size='large'
                    onClick={() => {
                      setDialog({ open: false })
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            )
          }}
        </Formik>
      </Dialog>
    </>
  )
}

export default PlumbingPage
