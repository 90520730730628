import React from 'react'
import { Typography } from '@material-ui/core'

const style = {
  label: {
    /* Style as you please, it will become the visible UI component. */
    cursor: 'pointer',
    fontWeight: '700',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    fontSize: '10px'
  },
  container: {
    backgroundColor: 'rgb(0, 0, 128)',
    padding: '0.3em 0.6em',
    width: 'fit-content',
    color: '#F1F1FF',
    margin: '0.2em 0',
    borderRadius: '5px',
    fontSize: '12px',
    fontWeight: '500',
    display: 'inline-flex'
  },
  uploadPhoto: {
    opacity: 0,
    position: 'absolute',
    zIndex: -1
  },
  badge: {
    backgroundColor: 'red'
  }
}

const CustomFileInput = ({ files, onChange, multiple, onDelete, accept }) => {
  return (
    <div style={style.container}>
      <label
        for='upload-photo'
        style={style.label}
        // className="font-size-sm text-first bg-neutral-first ml-4 rounded-sm shadow-none hover-scale-sm border-0 p-2 d-inline-flex align-items-center justify-content-center"
      >
        {multiple && files.length > 0
          ? 'Add more files'
          : !multiple && files
          ? 'Change File'
          : 'Upload files'}
      </label>
      <input
        type='file'
        name='photo'
        multiple={multiple}
        id='upload-photo'
        style={style.uploadPhoto}
        accept={accept}
        onChange={e => onChange(e)}
      />
      {multiple ? (
        files.map(f => (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'nowrap'
              }}
            >
              <Typography
                style={{
                  backgroundColor: f ? '#021835' : '',
                  padding: '3px',
                  borderRadius: '5px',
                  marginRight: '5px',
                  fontSize: '12px'
                }}
              >
                {f ? (f.name ? f.name : f.originalFileName) : null}
              </Typography>
              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => onDelete(f)}
              >
                x
              </Typography>
            </div>
          </div>
        ))
      ) : (
        <div style={files ? style.imageName : null}>{files ? files : null}</div>
      )}
    </div>
  )
}

export default CustomFileInput
