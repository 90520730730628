import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow,
  Typography,
  IconButton
} from '@material-ui/core'
import Table from '../../components/Table'
import { toCapitalize } from '../../utils/utils'
import api, { toFormData } from '../../libs/api'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import { Add, Edit, Delete, ArrowBack } from '@mui/icons-material'
import CustomerForm from '../../components/form/CustomerForm'
import { AuthContext } from '../../libs/contexts/AuthContext'
import { SnackbarContext } from '../../libs/contexts/SnackbarContext'
import { handleApiError } from '../../libs/handleApiError'
import DeleteDialog from '../../components/DeleteDialog'

const AddCustomerPage = props => {
  let navigate = useNavigate()
  let { id } = useParams()
  const { auth, isLoading } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [addDialog, setAddDialog] = useState({ open: false })

  // const fetchInfo = async (values) => {
  //   try {
  //     const { data } = await api.get(`v1/categories`)
  //     setCategories(data)
  //     // openSnackbar({ open: true, color: 'success', message: 'Successful' })
  //   } catch (error) {
  //     handleApiError(error, openSnackbar)
  //   }
  // }

  // const handleSubmit = async (values) => {
  //   try {
  //     const { data } = await api.post(`v1/customers`, toFormData(values))
  //     // fetchData()
  //     // setAddDialog({ open: false })
  //     navigate(-1)
  //   } catch (error) {
  //     handleApiError(error, openSnackbar)
  //   }
  // }
  const handleSubmit = async values => {
    try {
      const { data } = await api.post(`v1/customers`, values)
      // fetchData()
      // setAddDialog({ open: false })
      navigate(-1)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  useEffect(() => {}, [])

  // if (!categories) {
  //   isLoading(true)
  //   return 'Loading...'
  // }
  return (
    <>
      {/* {isLoading(false)} */}
      <Paper style={{ padding: '2em' }} elevation={5}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginBottom: '1em' }}
        >
          <Grid item style={{ display: 'flex' }}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
            <Typography
              variant='h6'
              style={{
                display: 'inline',
                marginTop: '.4em'
              }}
            >
              Add Customer
            </Typography>{' '}
          </Grid>
          <Grid item>
            {/* <Button
              variant='contained'
              color='secondary'
              startIcon={<Add />}
              onClick={() => {}}
            >
              Add Questions
            </Button> */}
          </Grid>
        </Grid>
        <Formik
          children={props => <CustomerForm {...props} formType='add' />}
          // enableReinitialize
          validationSchema={yup.object({
            companyname: yup.string().required('Required*'),
            contactperson: yup.string().required('Required*'),
            mobile: yup
              .number()
              .nullable()
              .typeError('Must be number')
              .required('Required*'),
            email: yup
              .string()
              .email('Invalid Email')
              .required('Required*'),
            status: yup
              .string()

              .required('Required*'),
            city: yup.string().required('Required'),
            country: yup.string().required()
          })}
          initialValues={{
            companyname: '',
            contactperson: '',
            mobile: null,
            email: '',
            status: '',
            city: '',
            country: ''
          }}
          onSubmit={handleSubmit}
        />
      </Paper>
    </>
  )
}

export default AddCustomerPage
