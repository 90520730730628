import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow,
  Typography,
} from "@material-ui/core";
import Table from "../../components/Table";
import { toCapitalize } from "../../utils/utils";
import api from "../../libs/api";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import DeleteDialog from "../../components/DeleteDialog";
import { Add, Edit, Delete } from "@mui/icons-material";
import { AuthContext } from "../../libs/contexts/AuthContext";
import { SnackbarContext } from "../../libs/contexts/SnackbarContext";
import { handleApiError } from "../../libs/handleApiError";
import SortIcon from "@material-ui/icons/Sort";

const ProductsPage = (props) => {
  let navigate = useNavigate();
  let { id } = useParams();
  const { auth, isLoading } = useContext(AuthContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const [products, setProducts] = useState();
  const [addDialog, setAddDialog] = useState({ open: false });
  const [deleteDialog, setDeleteDialog] = useState({ open: false });

  const fetchData = async (values) => {
    try {
      const { data } = await api.get(`v1/products`);
      setProducts(data);
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };
  const handleAddSubmit = async (values) => {
    try {
      const { data } = await api.post(`v1/admin`, values);
      fetchData();
      setAddDialog({ open: false });
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };
  const handleDelete = async (id) => {
    try {
      const { data } = await api.delete(`v1/products/${id}`);
      fetchData();
      setDeleteDialog({ open: false });
      openSnackbar({
        open: true,
        color: "success",
        message: "Successfully Deleted",
      });
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!products) {
    isLoading(true);
    return "Loading...";
  }
  return (
    <>
      {isLoading(false)}
      <DeleteDialog
        dialog={deleteDialog}
        handleCancel={() => setDeleteDialog({ open: false })}
      />
      <Paper style={{ padding: "1em 2em 0 2em" }} elevation={5}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: "1em" }}
        >
          <Grid item style={{ display: "flex" }}>
            {/* <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton> */}
            <Typography
              variant="h6"
              style={{ display: "inline", marginTop: ".5em" }}
            >
              Products
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SortIcon />}
              style={{ marginRight: "1em" }}
              onClick={() => navigate("/product/rearrange")}
            >
              Re-arrange
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => navigate("/product/add")}
            >
              Add Product
            </Button>
          </Grid>
        </Grid>
        <Table
          title={""}
          columns={[
            { field: "productnumber", title: "Product Number" },
            { field: "productmodel", title: "Model Number" },
            { field: "headertext", title: "Header Text" },
            // { field: 'materialcost', title: 'Material Cost' },
            { field: 'productprices', title: 'Product Price',render:(rowData)=>(Number(rowData.productprices)) }
            //     render: (rowData) => (
            //       <img
            //         src={rowData.url}
            //         style={{ width: 50, borderRadius: "50%" }}
            //       />
            //     ),
          ]}
          data={products}
          actions={[
            // {
            //   icon: () => <Add />,
            //   tooltip: 'Add Products',
            //   isFreeAction: true,
            //   onClick: () => {
            //     //   setAddDialog({ open: true })
            //     navigate('/product/add')
            //   }
            // },
            {
              icon: () => <Edit />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                navigate(`/product/edit/${rowData.id}`);
              },
            },
            (rowData) => ({
              icon: () => <Delete />,
              tooltip: "Delete",
              onClick: (event, rowData) => {
                setDeleteDialog({
                  open: true,
                  handleDelete: () => handleDelete(rowData.id),
                  message: `Are you sure you want to delete?`,
                });
              },
              // disabled: rowData.birthYear < 2000
            }),
          ]}
          options={{
            actionsColumnIndex: -1,
            // search: false,
            // header: false,
            // toolbar: false
          }}
          components={{
            Container: (props) => <div {...props}></div>,
          }}
        />
      </Paper>
      <Dialog
        open={addDialog.open}
        onClose={() => {
          setAddDialog({ open: false });
        }}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Grow}
      >
        <DialogTitle id="alert-dialog-title">Add</DialogTitle>
        <Formik
          validationSchema={yup.object().shape({
            name: yup
              .string()
              // .min(10,"Mobile is invalid")
              .required("Name is required."),
          })}
          initialValues={{
            // id: addDialog.id,
            name: "",
          }}
          onSubmit={handleAddSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <DialogContent style={{ margin: "0 1em 1em 1em" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="name"
                        // value={values.mobile}
                        // component={PhoneNumberInput}
                        // setFieldValue={(value) => {
                        //   setFieldValue("mobile", value);
                        // }}
                        label="Name"
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions style={{ margin: "0 2em 1em 0" }}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    // onClick={() => {
                    //   //   handleSendRequest();
                    // }}
                  >
                    Confirm
                  </Button>
                  <Button
                    size="large"
                    onClick={() => {
                      setAddDialog({ open: false, reviews: [] });
                    }}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default ProductsPage;
