import React, { useState, useEffect, createContext } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grow
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import LinearLoader from '../../components/loader/LinearLoader'

export const AuthContext = createContext()

const AuthContextProvider = props => {
  const [auth, setAuth] = useState({ authToken: null, data: null })
  const [logoutDialog, setLogoutDialog] = useState({
    open: false
  })
  const [loading, setLoading] = useState(false)
  const isAuth = value => {
    if (localStorage.getItem('authToken')) {
      if (localStorage.getItem('role') == 'Customer') {
        console.log('Customer')
        return 2
      } else {
        return 1
      }
    } else {
      return 0
    }
    // return localStorage.getItem('authToken') ? true : false
  }
  const openLogoutDialog = () => {
    setLogoutDialog({ open: true })
  }
  const handleSetAuth = data => {
    localStorage.setItem('authToken', `Bearer ${data.authToken}`)
    localStorage.setItem('data', JSON.stringify(data.data))
    setAuth({ authToken: `Bearer ${data.authToken}`, data: data.data })
  }
  const handleLogout = () => {
    localStorage.clear()
    handleCancel()
  }
  const handleCancel = () => {
    setLogoutDialog({ open: false })
  }
  const isLoading = value => {
    setLoading(value)
  }

  useEffect(() => {
    setAuth({
      authToken: localStorage.getItem('authToken'),
      data: localStorage.getItem('data')
    })
  }, [])

  return (
    <>
      <AuthContext.Provider
        value={{
          auth: auth,
          isAuth: isAuth,
          handleSetAuth: handleSetAuth,
          openLogoutDialog: openLogoutDialog,
          loading: loading,
          isLoading: isLoading
        }}
      >
        {loading && <LinearLoader />}
        {props.children}
        <Dialog
          open={logoutDialog.open}
          onClose={() => {
            setLogoutDialog({ open: false })
          }}
          fullWidth={false}
          maxWidth='sm'
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          TransitionComponent={Grow}
        >
          {/* <DialogTitle id='alert-dialog-title'>LogOut</DialogTitle> */}
          <DialogContent
            style={{
              margin: '1me 1em 1em 1em',
              paddingBottom: '1em',
              fontWeight: 'bold',
              fontSize: '1.5em'
            }}
          >
            Are you sure you want to Logout ?
          </DialogContent>
          <DialogActions style={{ margin: '0 2em 1em 0' }}>
            <Button
              type='submit'
              color='primary'
              style={{ backgroundColor: 'red' }}
              variant='contained'
              onClick={handleLogout}
              size='small'
            >
              Logout
            </Button>
            <Button size='small' onClick={handleCancel}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </AuthContext.Provider>
    </>
  )
}

export default AuthContextProvider
