import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grow,
  Typography,
  IconButton,
} from "@material-ui/core";
import Table from "../../components/Table";
import { toCapitalize } from "../../utils/utils";
import api, { toFormData } from "../../libs/api";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import { Add, Edit, Delete, ArrowBack } from "@mui/icons-material";
import ProductForm from "../../components/form/ProductForm";
import { AuthContext } from "../../libs/contexts/AuthContext";
import { SnackbarContext } from "../../libs/contexts/SnackbarContext";
import { handleApiError } from "../../libs/handleApiError";
import FormikSelect from "../../components/FormikSelect";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndCard } from "../../components/DndCard";
import update from "immutability-helper";

const RearrangeProductPage = (props) => {
  let navigate = useNavigate();
  let { id } = useParams();
  const { auth, isLoading } = useContext(AuthContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const [products, setProducts] = useState();
  const [categories, setCategories] = useState();
  const [cards, setCards] = useState();

  const fetchProducts = async (values) => {
    try {
      const { data } = await api.get(`v1/products`);
      setProducts(data);
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };

  const fetchCategories = async (values) => {
    try {
      const { data } = await api.get(`v1/categories`);
      setCategories(data);
      // openSnackbar({ open: true, color: 'success', message: 'Successful' })
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const { data } = await api.put(`v1/products/sorting`, cards);
      openSnackbar({ open: true, color: "success", message: "Successfully Rearranged Products" });
      // fetchData()
      // setAddDialog({ open: false })
      navigate(-1);
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const renderCard = useCallback((card, index) => {
    return (
      <DndCard
        key={card.id}
        index={index}
        id={card.id}
        text={card.text}
        moveCard={moveCard}
      />
    );
  }, []);

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, []);

  if (!products || !categories) {
    isLoading(true);
    return "Loading...";
  }
  return (
    <>
      {isLoading(false)}
      <Paper style={{ padding: "2em" }} elevation={5}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: "1em" }}
        >
          <Grid item style={{ display: "flex" }}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
            <Typography
              variant="h6"
              style={{
                display: "inline",
                marginTop: ".4em",
              }}
            >
              Rearrange Product
            </Typography>{" "}
          </Grid>
          <Grid item>
            {/* <Button
              variant='contained'
              color='secondary'
              startIcon={<Add />}
              onClick={() => {}}
            >
              Add Questions
            </Button> */}
          </Grid>
        </Grid>
        <Formik
          validationSchema={yup.object().shape({
            categorlevel1id: yup.string().required("Required*"),
            categorlevel2id: yup.string().required("Required*"),
            categorlevel3id: yup.string().required("Required*"),
          })}
          initialValues={{
            categorlevel1id: "",
            categorlevel2id: "",
            categorlevel3id: "",
            allCategories: categories,
            categoryOptionsLevel1: categories.filter(
              (c) => (c.name == "Plumbing" || c.name == "HVAC") && !c.parent
            ),
            categoryOptionsLevel2: [],
            categoryOptionsLevel3: [],
            categoryOptionsLevel4: [],
          }}
          // onSubmit={handleAddBookingSubmit}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <DialogContent style={{ margin: "0 1em 1em 1em" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Field
                        component={FormikSelect}
                        label="Category Level 1"
                        name="categorlevel1id"
                        value={values.categorlevel1id}
                        //   multiple
                        onChange={(e) => {
                          setFieldValue("categorlevel1id", e.target.value);
                          setFieldValue("categorlevel2id", "");
                          setFieldValue("categorlevel3id", "");
                          setFieldValue(
                            "categoryOptionsLevel2",
                            values.allCategories.filter(
                              (c) => c.parent == e.target.value
                            )
                          );
                        }}
                        size="small"
                        options={values.categoryOptionsLevel1.map((item) => {
                          return { label: item.name, value: item.id };
                        })}
                        textField="label"
                        valueField="value"
                        // inputProps={{ readOnly: view }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        component={FormikSelect}
                        label="Category Level 2"
                        name="categorlevel2id"
                        value={values.categorlevel2id}
                        //   multiple
                        onChange={(e) => {
                          setFieldValue("categorlevel2id", e.target.value);
                          setFieldValue("categorlevel3id", "");
                          setFieldValue(
                            "categoryOptionsLevel3",
                            values.allCategories.filter(
                              (c) => c.parent == e.target.value
                            )
                          );
                        }}
                        size="small"
                        options={values.categoryOptionsLevel2.map((item) => {
                          return { label: item.name, value: item.id };
                        })}
                        textField="label"
                        valueField="value"
                        // inputProps={{ readOnly: view }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        component={FormikSelect}
                        label="Category Level 3"
                        name="categorlevel3id"
                        value={values.categorlevel3id}
                        //   multiple
                        onChange={(e) => {
                          setFieldValue("categorlevel3id", e.target.value);
                          // setFieldValue(
                          //   'categoryOptionsLevel4',
                          //   values.allCategories.filter(c => c.parent == e.target.value)
                          // )
                          setCards(
                            products
                              .filter(
                                (item) =>
                                  item.categorlevel1id ==
                                    values.categorlevel1id &&
                                  item.categorlevel2id ==
                                    values.categorlevel2id &&
                                  item.categorlevel3id == e.target.value
                              )
                              .sort((a, b) => a.sortIndex - b.sortIndex)
                              .map((item, index) => ({
                                ...item,
                                text:
                                  item.productnumber + " - " + item.headertext,
                              }))
                          );
                          setFieldValue("categorlevelname", e.target.label);
                        }}
                        size="small"
                        options={values.categoryOptionsLevel3.map((item) => {
                          return { label: item.name, value: item.id };
                        })}
                        textField="label"
                        valueField="value"
                        // inputProps={{ readOnly: view }}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} lg={6}>
                      <DndProvider backend={HTML5Backend}>
                        {cards ? (
                          cards.map((card, i) => renderCard(card, i))
                        ) : (
                          <div style={{ fontWeight: "bold" }}>
                            Select Categories to Rearrange products
                          </div>
                        )}
                      </DndProvider>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions style={{ margin: "0 2em 1em 0" }}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Confirm
                  </Button>
                  <Button
                    size="large"
                    onClick={() => {
                      navigate(-1);
                      // setAddBookingDialog({ open: false });
                    }}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </>
  );
};

export default RearrangeProductPage;
