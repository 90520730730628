import moment from 'moment-timezone';

export function shiftPickerDateToTZDate(pickerDate) {
    let pickerOffset = pickerDate.getTimezoneOffset();
    let utcDate = new Date();
    utcDate.setTime(pickerDate.getTime() - pickerOffset * 60000);
  
    let tzOffset = moment
      .tz(pickerDate, 'America/Winnipeg|Canada/Central')
      .utcOffset();
    let tzDate = new Date();
    tzDate.setTime(utcDate.getTime() - tzOffset * 60000);
    return tzDate;
  }
  
  export function shiftTzDateToPickerDate(tzDate) {
    let tzUTCOffset = moment
      .tz(tzDate, 'America/Winnipeg|Canada/Central')
      .utcOffset();
    let utcDate = new Date();
    utcDate.setTime(tzDate.getTime() + tzUTCOffset * 60000);
  
    let pickerDate = new Date();
    let pickerOffset = pickerDate.getTimezoneOffset();
    pickerDate.setTime(utcDate.getTime() + pickerOffset * 60000);
  
    return pickerDate;
  }

  export const formatDate = date => {
    if (!date) {
      return ''
    }
    let usDate = shiftTzDateToPickerDate(date)
    return (
      (usDate.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      usDate
        .getDate()
        .toString()
        .padStart(2, '0') +
      '-' +
      usDate.getFullYear()
    )
  }